<section class="bg-color-secondary-lighter py-5 py-xl-6">
  <div class="container mb-3 mb-md-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
        <h1 class="color-secondary mb-4 mb-md-5">Our innovations are validated and supported by Global Organizations
            and Hospitals</h1>
        <h2 class="color-secondary mb-2">Validation partners</h2>
        <hr class="w-60 mx-auto mb-0 text-secondary">
      </div>
    </div>
  </div>
  <div class="container overflow-hidden">
    <div class="row gy-5 gy-md-6">
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/KEM Hospital.png">
      </div>
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/Nanavati.png">
      </div>
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/AIIMS Nagpur.png">
      </div>
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/Fernandez.png">      
      </div>
    </div>
  </div>
  <div class="container mb-3 mb-md-5">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
        <h2 class="color-secondary mb-2">Technology partners</h2>
        <hr class="w-60 mx-auto mb-0 text-secondary">
      </div>
    </div>
  </div>
  <div class="container overflow-hidden">
    <div class="row gy-5 gy-md-6">
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/1.png">
      </div>
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/2.png">
      </div>
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/3.png">
      </div>
      <div class="col-6 col-md-3 align-self-center text-center mb-5">
        <img class="partner-img mt-4 mt-sm-3 mt-md-0" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/partners/4.png">      
      </div>
    </div>
  </div>
</section>