import { Component, OnInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';

@Component({
  selector: 'app-products-intro',
  templateUrl: './products-intro.component.html',
  styleUrls: ['./products-intro.component.scss']
})
export class ProductsIntroComponent implements OnInit {
  docs:number=2000;
  infoStmt=`More than ${this.docs}+ healthcare providers trust us for
            offering best pregnancy care solutions i.e,`;
  constructor(private landingService:LandingDataService) { }

  ngOnInit() {
    this.landingService.currentPlatformNumbers.subscribe(data=>{
      if(data && data.totalDoctors){
        this.docs=data.totalDoctors;
        this.infoStmt=`More than ${this.docs}+ healthcare providers trust us for
            offering best pregnancy care solutions i.e,`;
      }
    });
  }

}
