<section>
    <div class="left-bg bg-color-secondary">
        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/components/feto-05.png">
    </div>
    <div class="container-fluid inner-section">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="details-container d-flex flex-column justify-content-center mb-4 mb-md-0" data-aos="fade-up" data-aos-delay="100">
                    <div class="glide-container">
                        <div class="glide glide__fetosense_testimonials">
                            <div class="glide__track" data-glide-el="track">
                                <ul class="glide__slides">
                                    <li class="glide__slide">
                                        <div class="slide-inner">
                                            <div class="slide-img">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/testimonials/cloudnine.jpeg">
                                            </div>
                                            <div class="slide-data">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="mb-3" src="../../../../../assets/images/products/fetosense/testimonials/feto icons-10.png">
                                                <div class="mb-3">
                                                    <p class="font-16 color-primary">
                                                        We are very pleased to partner with FetoMax and offer 
                                                        a new service for our customers. This partnership helps 
                                                        us to take BabyBeat to customers in their homes. 
                                                        This is a relevant service in current circumstances where 
                                                        people don't want to come to hospital and prefer to consume 
                                                        services remotely.
                                                    </p>
                                                </div>
                                                <div class="mb-3">
                                                    <p class="color-secondary">Nitin Nag, Regional Director (South & West)</p>
                                                    <p class="color-primary">Cloudnine Hospital</p>
                                                </div>
                                                
                                                <div class="glide__arrows d-none d-sm-flex align-items-center" data-glide-el="controls">
                                                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--2nd slide-->
                                    <li class="glide__slide">
                                        <div class="slide-inner">
                                            <div class="slide-img">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/testimonials/doctor_image.jpg">
                                            </div>
                                            <div class="slide-data">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="mb-3" src="../../../../../assets/images/products/fetosense/testimonials/feto icons-10.png">
                                                <div class="mb-3">
                                                    <p class="font-16 color-primary">
                                                        Device is very handy and works well in sensing toco and FHR together. Sharing the report and remote monitoring is very easy so as to do get expert opinion...good machine overall.
                                                    </p>
                                                </div>
                                                <div class="mb-3">
                                                    <p class="color-secondary">Dr. Shivangi Joshi</p>
                                                    <p class="color-primary">GMCH Nagpur</p>
                                                </div>
                                                
                                                <div class="glide__arrows d-none d-sm-flex align-items-center" data-glide-el="controls">
                                                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--3rd slide-->
                                    <li class="glide__slide">
                                        <div class="slide-inner">
                                            <div class="slide-img">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/testimonials/dr swapnita.jpeg">
                                            </div>
                                            <div class="slide-data">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="mb-3" src="../../../../../assets/images/products/fetosense/testimonials/feto icons-10.png">
                                                <div class="mb-3">
                                                    <p class="font-16 color-primary">
                                                        It was a great experience having FetoMax machine, right from my counselling, to buying, gave me a grace period by supporting to set up the machine in my clinic and when i was not convinced online, one of the FetoMax member came to odisha.
                                                        Taking my positive/negative reviews and executing them is really great. Very professional and giving it a personal touch too. Hope i will get a great service in the future too.
                                                    </p>
                                                </div>
                                                <div class="mb-3">
                                                    <p class="color-secondary">Dr. Swapnita Hota</p>
                                                    <p class="color-primary">Bhubaneswar</p>
                                                </div>
                                                
                                                <div class="glide__arrows d-none d-sm-flex align-items-center" data-glide-el="controls">
                                                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--4th slide-->
                                    <li class="glide__slide">
                                        <div class="slide-inner">
                                            <div class="slide-img">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/testimonials/dr sujit.jpeg">
                                            </div>
                                            <div class="slide-data">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="mb-3" src="../../../../../assets/images/products/fetosense/testimonials/feto icons-10.png">
                                                <div class="mb-3">
                                                    <p class="font-16 color-primary">
                                                        FetoMax is a very user-friendly device, providing accurate and detailed reports for the fetal heart monitoring for expecting mothers. The display system and carrying case makes it conveniently portable for use in multiple areas. The data is stored in the device and on Cloud, making their remote accessibitlity at all times possible.
                                                        The service is also good, and it comes with 5 years of maintenance and upgrading - being made in India which makes it even better. Highly recommended for all centers providing obstetric care to pregnant women.
                                                    </p>
                                                </div>
                                                <div class="mb-3">
                                                    <p class="color-secondary">Dr. Sujit Kumar</p>
                                                    <p class="color-primary">Bhubaneswar</p>
                                                </div>
                                                
                                                <div class="glide__arrows d-none d-sm-flex align-items-center" data-glide-el="controls">
                                                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--5th slide-->
                                    <li class="glide__slide">
                                        <div class="slide-inner">
                                            <div class="slide-img">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/testimonials/GMC Aurangabad.jpeg">
                                            </div>
                                            <div class="slide-data">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="mb-3" src="../../../../../assets/images/products/fetosense/testimonials/feto icons-10.png">
                                                <div class="mb-3">
                                                    <p class="font-16 color-primary">
                                                        CareMother FetoMax provides a CTG machine which is very helpful to monitor the fetus. Integrated system with advanced technology of providing every NST at your mobile is a god's gift to us as an obstretrician. Without the wastage of paper like old age NST, proper digital data collection and an easy way of CTG interpretation is also a supportive feature of this new technology.
                                                    </p>
                                                </div>
                                                <div class="mb-3">
                                                    <p class="color-secondary">Dr. Sandeep</p>
                                                    <p class="color-primary">GMCH Aurangabad</p>
                                                </div>
                                                
                                                <div class="glide__arrows d-none d-sm-flex align-items-center" data-glide-el="controls">
                                                    <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="glide__bullets d-flex d-lg-none" data-glide-el="controls[nav]">
                                <button class="glide__bullet" data-glide-dir="=0"></button>
                                <button class="glide__bullet" data-glide-dir="=1"></button>
                                <button class="glide__bullet" data-glide-dir="=2"></button>
                                <button class="glide__bullet" data-glide-dir="=3"></button>
                                <button class="glide__bullet" data-glide-dir="=4"></button>
                            </div>
                        </div>                    
                    </div>
                </div>                
            </div>
        </div>
    </div>
</section>