<section id="our-clients-section">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="color-primary">Our Clients</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12" >
                <div class="glide-container">
                    <div class="glider__clients__in glide">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/1.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/2.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/3.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/4.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/5.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/6.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/7.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/8.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/9.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/10.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/11.png"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/clients/12.png"> 
                                </li>
                                <!-- <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-32.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-33.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-34.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-35.svg"> 
                                </li> -->
                            </ul>
                        </div>
                        <!--<img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/featured/left faded.png" class="left-faded">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/featured/right faded.png" class="right-faded">-->
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>