<section id="footer">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12 d-block d-md-flex align-items-end justify-content-between">
                <div class="d-flex justify-content-center d-md-block">
                    <a routerLink="/">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/caremother-logo/CareMother_Logo.png" class="cm-logo">
                    </a>
                    <p class="color-light p-0 mb-0 mt-2 d-none d-md-block">CareMother is an online maternity solution 
                        platform incubated by IIT Bombay</p>
                </div>
                <!--<div class="d-flex flex-column justify-content-end mt-3 mt-md-0 d-none d-md-block">
                    <a href="https://forms.gle/QaAGwLQShknG96FV9" target="_blank" class="d-none d-md-block" color="secondary" button-filled>
                        Request Demo</a>
                </div>-->
            </div>
        </div>
        <div class="row mb-3 d-none d-md-block">
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row justify-content-between footer-links">
            <div class="col-md-5 mb-3 mb-md-0 top-links">
                <div>
                    <h2 class="color-primary text-center text-md-left">DOTO Health Pvt. Ltd.</h2>
                    <p class="mt-4 text-center text-md-left">
                        <a href="https://goo.gl/maps/qYKKDbtddCgh4inG7" target="_blank" class="color-light font-16 mt-3">
                            701, Deron Heights Varsha Park Apartment,
                            Above Ranka jewellers, Opposite Mahabaleshwar Restaurant,
                            Baner Road,
                            Baner,Pune
                            Pin. 411045
                            Maharashtra, India
                        </a><br><br>
                        <a href="https://maps.app.goo.gl/WxbVC1m8eqPxjcd96" target="_blank" class="color-light font-16 mt-3">
                            Office.No 6034, SINE, Rahul Bajaj Technology Innovation Centre,
                            IIT, Powai, Mumbai, 
                            Pin. 400076
                            Maharashtra, India
                        </a>
                    </p>
                </div>
                <div class="mt-5 mt-md-4">
                    <h2 class="color-primary text-center text-md-left">Download App</h2>
                    <div class="btn-group mt-3 justify-content-center justify-content-md-start">
                        <a class="btn-link" href="https://play.google.com/store/apps/details?id=app.doto.caremother.android"
                            target="_blank">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/footer/play-store.png" class="download-button mb-3">
                        </a>
                        <a class="btn-link apple-link" href="https://apps.apple.com/in/app/caremother-pregnancy-tracker/id1506728456"
                            target="_blank">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/shared/footer/apple-store.png" class="download-button">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex flex-column justify-content-between">
                <div class="nav-list">
                    <cdk-accordion class="example-accordion">
                        <cdk-accordion-item *ngFor="let item of navList"
                        #accordionItem="cdkAccordionItem"
                        class="example-accordion-item"
                        role="button"
                        tabindex="0"
                        [attr.expanded]="accordionItem.expanded"
                        [attr.id]="'accordion-header-' + index"
                        [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-' + index">
                            <div class="inner-accordian">
                                <div class="example-accordion-item-header accordian-inner" (click)="accordionItem.toggle();" >
                                    <div class="accordian-title">
                                        <p class="color-primary font-17">{{item.title}}</p>
                                        <i class="fa fa-angle-down" [class.rotate]="accordionItem.expanded" [class.rotate-inverse]="!accordionItem.expanded"></i>
                                    </div>
                                </div>
                                <div class="example-accordion-item-body"
                                    role="region"
                                    [style.display]="accordionItem.expanded ? '' : 'none'"
                                    [attr.id]="'accordion-body-' + index"
                                    [attr.aria-labelledby]="'accordion-header-' + index">
                                    <div class="accordian-inner" *ngFor="let navRoute of item.children"  >
                                        <p><a class="color-light font-16" [routerLink]="navRoute.route">{{navRoute.title}}</a></p>
                                    </div>
                                </div>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </div>
                <div class="container-links">
                    <div class="links-inner">
                        <h2 class="color-primary">Company</h2>
                        <div class="mt-4">
                            <ul class="list-inline">
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/company/about-us">About us</a></p>
                                </li>
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/company/life-at-caremother">Life At CareMother</a></p>
                                </li>
                                <!--<li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" href="http://blogs.caremother.in/">Blogs</a></p>
                                </li>-->
                            </ul>
                        </div>
                    </div>
                    <div class="links-inner">
                        <h2 class="color-primary">Offerings</h2>
                        <div class="mt-4">
                            <ul class="list-inline">
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/offering/fetomax">FetoMax</a></p>
                                </li>
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/offering/feto-twin">FetoTwin</a></p>
                                </li>
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/offering/feto-mini">FetoMini</a></p>
                                </li>
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/offering/babybeat">BabyBeat</a></p>
                                </li>
                                <li class="footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/offering/anandimaa">AnandiMaa</a></p>
                                </li>
                                <li class="footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/offering/careHEr">careHER</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="links-inner">
                        <h2 class="color-primary">Connect</h2>
                        <div class="mt-4">
                            <ul class="list-inline">
                                <li class="mb-2 footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/contact">Contact</a></p>
                                </li>
                                <li class="footer-contain-text">
                                    <p><a class="color-light font-16" routerLink="/company/careers">Career</a></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="support-socials d-none d-md-flex flex-column align-items-end">
                    <a href="tel:+919930990900"><h4 class="color-primary">Support No: +91 99309 90900</h4></a>
                    <div class="socials-icons">
                        <a href=" https://www.facebook.com/caremotherindia/" class="color-secondary" target="_blank">
                            <i class="fa fa-facebook-square" aria-hidden="true"></i>
                        </a>
            
                        <a href=" https://www.instagram.com/caremotherbydotohealth/" class="color-secondary" target="_blank">
                            <i class="fa fa-instagram mx-3" aria-hidden="true"></i>
                        </a>
                        
                        <a href="https://www.linkedin.com/company/caremother/" class="color-secondary" target="_blank">
                            <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                       </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 top-links">
                <hr>
                
                <div class="socials mb-3">
                    <a href=" https://www.facebook.com/caremotherindia/" class="color-secondary" target="_blank">
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                    </a>
        
                    <a href="https://www.instagram.com/caremotherbydotohealth/" class="color-secondary" target="_blank">
                        <i class="fa fa-instagram mx-4" aria-hidden="true"></i>
                    </a>
        
                    <a href="https://www.linkedin.com/company/caremother/" class="color-secondary" target="_blank">
                        <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                   </a>
                </div>
            </div>
        </div>
        <div class="row  flex-column-reverse justify-content-center flex-lg-row justify-content-lg-between mb-5 footer-links">
            <div class="col-12 col-lg mb-3 mb-sm-0 top-links bottom-links">
                <p>
                    <a class="color-light font-16" routerLink="/terms-and-conditions">Terms & Conditions</a>
                </p>
                <!-- <p>
                    <a class="color-light font-16" routerLink="/sitemap">Sitemap</a>
                </p> -->
                <p>
                    <a class="color-light font-16" routerLink="/privacy-policy">Privacy Policy</a>
                </p>
                <p>
                    <a class="color-light font-16" routerLink="/cancellation-and-return-policy">Cancellation and return policy</a>
                </p>
            </div>
            <div class="col d-flex justify-content-center mb-4 mb-lg-0 justify-content-lg-end">
                <p class="color-light text-center text-lg-left font-16">
                    &copy; {{ test | date: "yyyy" }} DOTO Health Pvt. Ltd. <br class="d-block d-lg-none">all rights reserved.
                </p>
            </div>
        </div>
    </div>
</section>