import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from  '@angular/forms';
import { ContactService } from '../../contact.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {
  subscribeForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private contactService: ContactService, private http: HttpClient) { }

  ngOnInit() {
    let emailPattern = "^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

    this.subscribeForm  =  this.formBuilder.group({
      email: ['',[ 
        Validators.required,
        Validators.pattern(emailPattern),
      ]],
    });
  }

  
  get f() { return this.subscribeForm.controls; }

  subscribe(){

    if(this.subscribeForm.invalid){

    }
    else{

      let subscribeValues = {
        phone: " ",
        email: this.subscribeForm.value.email,
        name: " ",
        subject: "Newsletter Subscription",
        message: "I would like to subscribe to caremother newsletter",
        apiKey : 'pB9DJrDNaAOWiPoS9bBT'
      }

      this.http.post('https://api.dotohealth.com:3000/api/sendInquiries', subscribeValues).
        subscribe(
          (data) => {
            Swal.fire({
              html:'<h1 class="swal-title">Thank You For Subscribing!</h1><p class="swal-desc">You have successfully subscribed to our list. You will be receiving a confirmation email soon.<br class="d-none d-md-block"> Now, you can stay up-to-date with all the CareMother news.</p>',
              icon: 'success',
              width: '50rem',
              iconColor: '#00A69A',
              confirmButtonColor: '#00A69A',
              confirmButtonText: 'Go Back',
              focusConfirm: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false
            })
            this.subscribeForm.reset();
          },
          (error: HttpErrorResponse) => {
            
            Swal.fire({
              title:"Error",
              text:"Something wends wrong please try again",
              icon:'error'
            })
            console.error(error,"error")
          }
  
        )
    /*
      let user = this.contactService.checkUser(this.subscribeForm.value.email).subscribe((data)=>{
        if (data.length == 0) {
          this.contactService.subscribeUser(this.subscribeForm.value.email).then(result=>{
            Swal.fire({
              html:'<h1 class="swal-title">Thank You For Subscribing!</h1><p class="swal-desc">You have successfully subscribed to our list. You will be receiving a confirmation email soon.<br class="d-none d-md-block"> Now, you can stay up-to-date with all the CareMother news.</p>',
              icon: 'success',
              width: '50rem',
              iconColor: '#00A69A',
              confirmButtonColor: '#00A69A',
              confirmButtonText: 'Go Back',
              focusConfirm: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false
            })
            this.subscribeForm.reset();
            user.unsubscribe();
      
          }).catch((error)=>{
            Swal.fire({
              title:"Error",
              text:"Something wends wrong please try again",
              icon:'error'
            })
            console.error(error,"error")
            user.unsubscribe();
          });
        } else {
          Swal.fire({
            html:'<h1 class="swal-title">User Already Subscribed!</h1>',
            icon: 'error',
            width: '50rem',
            //iconColor: '#00A69A',
            confirmButtonColor: '#00A69A',
            confirmButtonText: 'Go Back',
            focusConfirm: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
          })
          user.unsubscribe();
        }
      })*/
    }
  }

}
