import { Component, OnInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';

@Component({
  selector: 'app-anandimaa-presense',
  templateUrl: './anandimaa-presense.component.html',
  styleUrls: ['./anandimaa-presense.component.scss']
})
export class AnandimaaPresenseComponent implements OnInit {
  countries:number=4;
  states:number=15;
  hw:number=1200;
  info1=`CareMother AnandiMaa in ${this.countries} Countries and ${this.states}+ States in India`;
  info2=`with ${this.hw}+ Health Workers & Doctors onboarded`;

  constructor(private landingService:LandingDataService) { }

  ngOnInit(): void {
    this.landingService.currentPlatformNumbers.subscribe(data=>{
      if(data && data.anandiMaaRollingCount){
        this.countries=data.anandiMaaInCountries;
        this.hw=data.anandiMaaDocAndHW;
        this.states=data.anandiMaaRollingCount.statesCovered;
        this.info1=`CareMother AnandiMaa in ${this.countries} Countries and ${this.states}+ States in India`;
        this.info2=`with ${this.hw}+ Health Workers & Doctors onboarded`;
      }
    });
  }

}
