<section id="anandimaa-presense">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col">
                <div class="inner-block">
                    <h1 class="color-secondary text-center">
                        Our Presence
                    </h1>
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-center img-container">
                        <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="img-world" src="../../../../../assets/images/products/anandimaa/presense/world.png">
                        <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="img-india" src="../../../../../assets/images/products/anandimaa/presense/india.png">
                    </div>
                    <div class="d-flex justify-content-center">
                        <span id="legend"></span>
                        <p class="text-center ml-2">
                            {{info1}}<br class="d-none d-sm-block">
                            {{info2}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>