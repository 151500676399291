import { Component, OnInit } from '@angular/core';
import { Swiper } from 'swiper';

@Component({
  selector: 'app-offering',
  templateUrl: './offering.component.html',
  styleUrls: ['./offering.component.scss']
})
export class OfferingComponent implements OnInit {

  constructor() { }

  swiper: Swiper;


  ngOnInit() {
    // const swiper = new Swiper('.mySwiper', {
    //   navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //   },
    //   pagination: {
    //       el: '.swiper-pagination',
    //       clickable: true,
    //   },
    //   loop: true,
    //   breakpoints: {
    //       320: {   // For small screens
    //           slidesPerView: 1,
    //           spaceBetween: 10
    //       },
    //       768: {   // For medium screens
    //           slidesPerView: 2,
    //           spaceBetween: 20
    //       },
    //       1024: {  // For large screens
    //           slidesPerView: 3,
    //           spaceBetween: 30
    //       }
    //   }
    // });
  }

  ngAfterViewInit() {
    this.swiper = new Swiper('.mySwiper', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      loop: true,
      breakpoints: {
        320: {   // For small screens
          slidesPerView: 1,
          spaceBetween: 10
        },
        768: {   // For medium screens
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {  // For large screens
          slidesPerView: 3,
          spaceBetween: 30
        }
      }
    });

    // console.log(this.swiper.slidesPerViewDynamic)
  }

}
