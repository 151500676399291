import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

interface Utility{
    anandiMaaDocAndHW:number,
      anandiMaaInCountries:number,
      anandiMaaRollingCount:{
        mothersReached:number,
        partnersJoined:number,
        statesCovered:number,
        villageCovered:number,
        visitsMade:number
      },
      fetomaxTotalMothers:string,
      fetomaxTotalStates:number,
      homeRollingCount:{
        totalCountries:number,
        totalMothers:number,
        totalOrgs:number,
        totalStates:number
      },
      totalDoctors:number
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private afs: AngularFirestore) { }

  getUtility(){
    return this.afs.collection('websiteConfig').doc('utility').valueChanges();
  }

  getOpeningsDetails(id){
    return this.afs.collection('Openings', ref => ref.where('documentId','==',id)).valueChanges();
  }
}
