<section id="testimonials-section">
    <div class="container inner-section">
        <div class="row mb-5" >
            <div class="col-12 text-center header">
                <p class="color-secondary mb-3">Testimonials</p>
                <h1 class="text-white mb-2">Our happy customers</h1>
                <!--<p class="color-light">
                    Don't miss out this chance to read some amazing testimonials from
                    doctors, hospitals and mothers<br class="d-none d-lg-block"> 
                    about ther life-changing experience 
                    with CareMother and it's advanced offerings.
                </p>-->
            </div>
        </div>
        <div class="row" >
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glide__testimonials">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="slide-inner bg-color-white">
                                        <div class="slide-img">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/testimonials/dr swapnita.jpeg">
                                        </div>
                                        <div class="slide-data">
                                            <div class="product-name text-center bg-color-secondary">
                                                <i class="text-white">Product: FetoMax</i>
                                            </div>
                                            <div class="mb-4 mb-lg-5">
                                                <p class="font-16 color-primary">
                                                    It was a great experience having FetoMax machine, 
                                                    right from my counseling, to buying, gave me a grace 
                                                    period by supporting to set up the machine in my clinic 
                                                    and when i was not convinced online, one of the FetoMax 
                                                    member came to odisha. Taking my positive/negative reviews 
                                                    and executing them is really great. Very professional and 
                                                    giving it a personal touch too. Hope i will get a great 
                                                    service in the future too.
                                                </p>
                                            </div>
                                            <div>
                                                <p class="color-secondary">Dr. Swapnita Hota</p>
                                                <p class="color-primary">Bhubaneswar</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="slide-inner bg-color-white">
                                        <div class="slide-img">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/testimonials/Shradhha Ladkat.jpg">
                                        </div>
                                        <div class="slide-data">
                                            <div class="product-name text-center bg-color-secondary">
                                                <i class="text-white">Product: BabyBeat</i>
                                            </div>
                                            <div class="mb-4 mb-lg-5">
                                                <p class="font-16 color-primary">
                                                    Thank you soo much for inventing this Device . For twin or multiple pregnancies it really helps alot. 
                                                    My gynec suggested me to use this machine for tracking my twin babies heart rates and movements which 
                                                    really made me easy to track my babies heart rates & share my reports directly with my dr through their
                                                    app. I’ll definitely recommend this device.
                                                </p>
                                            </div>
                                            <div>
                                                <p class="color-secondary">Shradha Ladket</p>
                                                <p class="color-primary">Mother</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="slide-inner bg-color-white">
                                        <div class="slide-img">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/testimonials/dr sriram gopal.jpeg">
                                        </div>
                                        <div class="slide-data">
                                            <div class="product-name text-center bg-color-secondary">
                                                <i class="text-white">Product: FetoMax</i>
                                            </div>
                                            <div class="mb-4 mb-lg-5">
                                                <p class="font-16 color-primary">
                                                    FetoMax makes it easier to share its result via WhatsApp 
                                                    as it is the most common form of communication, I'm able to 
                                                    monitor the graph on my desk while the patient is strapped 
                                                    on with the tranducer in the next room.
                                                </p>
                                            </div>
                                            <div>
                                                <p class="color-secondary">Dr. Sriram Gopal</p>
                                                <p class="color-primary">M.D DGO. D.N.B</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="slide-inner bg-color-white">
                                        <div class="slide-img">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/testimonials/cloudnine.jpeg">
                                        </div>
                                        <div class="slide-data">
                                            <div class="product-name text-center bg-color-secondary">
                                                <i class="text-white">Product: FetoMax</i>
                                            </div>
                                            <div class="mb-4 mb-lg-5">
                                                <p class="font-16 color-primary">
                                                    We are very pleased to partner with FetoMax and offer 
                                                    a new service for our customers. This partnership helps 
                                                    us to take BabyBeat to customers in their homes. 
                                                    This is a relevant service in current circumstances where 
                                                    people don't want to come to hospital and prefer to consume 
                                                    services remotely.
                                                </p>
                                            </div>
                                            <div>
                                                <p class="color-secondary">Nitin Nag, Regional Director (South & West)</p>
                                                <p class="color-primary">Cloudnine Hospital</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="slide-inner bg-color-white">
                                        <div class="slide-img">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/testimonials/renuka mother.jpg">
                                        </div>
                                        <div class="slide-data">
                                            <div class="product-name text-center bg-color-secondary">
                                                <i class="text-white">Product: BabyBeat</i>
                                            </div>
                                            <div class="mb-4 mb-lg-5">
                                                <p class="font-16 color-primary">
                                                    Your baby heartbeat monitor was amazing. 
                                                    It is so easy to use without any hassle it connects via bluetooth. 
                                                    Heart rate is clearly monitored. 
                                                    The graph is so easy to share with the concerned doctors. 
                                                    Honestly your device saved my little one. 
                                                    Had the device not been there it would have been dreadful. 
                                                    Extremely grateful for your device. It was no less than a life savior.
                                                </p>
                                            </div>
                                            <div>
                                                <p class="color-secondary">Renuka</p>
                                                <p class="color-primary">Mother</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="slide-inner bg-color-white">
                                        <div class="slide-img">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/testimonials/Suchismita Sahoo.jpg">
                                        </div>
                                        <div class="slide-data">
                                            <div class="mb-4 mb-lg-5">
                                                <p class="font-16 color-primary">
                                                    Personal pregnancy care service was really helpful for me, 
                                                    when I was suggested to continuously monitor a specific condition. 
                                                    I took more than 12 home visits during my pregnancy.
                                                </p>
                                            </div>
                                            <div>
                                                <p class="color-secondary">Suchismita Sahoo</p>
                                                <p class="color-primary">Mother</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows d-none d-sm-block" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" title="testimonial-left">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" title="testimonial-right">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        
                        <div class="glide__bullets d-sm-none" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                            <button class="glide__bullet" data-glide-dir="=3"></button>
                            <button class="glide__bullet" data-glide-dir="=4"></button>
                            <button class="glide__bullet" data-glide-dir="=5"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>