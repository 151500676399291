import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta) {
      
    this.title.setTitle('CareMother - CTG Machine Manufacturer in India. 9930990900');
    this.meta.updateTag({ name: 'description', content: "106, First Floor, A Wing, Greenscape Technocity, Shilphata - Mahape Road,Near Country Inn Hotel, MIDC Industrial Area, Ghansoli, Navi Mumbai, 400710 India" })
    this.meta.updateTag({ name: 'keywords', content: "NST / CTG Machine in India, CTG Machine Manufacturer in India, FetoMax, CareMother" })
    }

  ngOnInit() {
    window.scrollTo(0, 0);}
  
  isFixed: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset > 400) {
        this.isFixed = true;
      } else if(offset <= 500){
        this.isFixed = false
      }
      else {
        this.isFixed = false;
      }
    }
  }

}
