<section>
    <div class="container inner-section">
        <div class="row text-container mb-5" >
            <div class="col-12 text-center">
                <p class="color-light mb-3">Discover Videos</p>
                <h1 class="color-secondary">
                    We aim to provide convenience with our innovations
                </h1>
            </div>
        </div>
        <div class="row video-container justify-content-center d-none d-sm-flex">
            <div class="col-lg-4 col-sm-6 mb-4 mb-md-0" >
                <div class="video-content">
                    <iframe title="CareMother providing pregnancy care at home" class="youtube-video" [src]='safeURL1' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 mb-4 mb-md-0" >
                <div class="video-content">
                    <iframe title="CareMother Homecare pregnancy service review by Dr. Sonali Domane" class="youtube-video" [src]='safeURL2' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 mt-md-4 mt-lg-0" >
                <div class="video-content">
                    <iframe title="Track Pregnancy now with Remote Monitoring" class="youtube-video" [src]='safeURL3' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="row video-container justify-content-center d-flex d-sm-none">
            <div class="col-md-12 col-sm-12">
                <div class="glide-container">
                    <div class="glide glide__videos">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="video-content">
                                        <iframe title="CareMother providing pregnancy care at home" class="youtube-video" [src]='safeURL1' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="video-content">
                                        <iframe title="CareMother Homecare pregnancy service review by Dr. Sonali Domane" class="youtube-video" [src]='safeURL2' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="video-content">
                                        <iframe title="Track Pregnancy now with Remote Monitoring" class="youtube-video" [src]='safeURL3' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        
                        <!--<div class="glide__bullets d-sm-none" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                        </div>-->
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>