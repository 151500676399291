<nav>
    <div class="container  inner-section">
        <div class="socials-links">
            <a href=" https://www.facebook.com/caremotherindia/" target="_blank">
                <i class="fa fa-facebook-square" aria-hidden="true"></i>
            </a>

            <a href=" https://www.instagram.com/caremotherbydotohealth/" target="_blank">
                <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>

            <a href="https://www.linkedin.com/company/caremother/" target="_blank">
                <i class="fa fa-linkedin-square" aria-hidden="true"></i>
           </a>
        </div>
        <div class="contact-links">
            <span>
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span class="lg-content">+91 99309 90900</span>
                <a class="sm-content" href="tel:+919930990900">+91 99309 90900</a>
            </span>
        </div>
    </div>
</nav>