import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-life-caremother',
  templateUrl: './life-caremother.component.html',
  styleUrls: ['./life-caremother.component.scss']
})
export class LifeCaremotherComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta) {
      
    this.title.setTitle('Life at CareMother');
    this.meta.updateTag({ name: 'description', content: "Life at CareMother"})
    this.meta.updateTag({ name: 'keywords', content: "Life at CareMother, CareMother" })
    }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
