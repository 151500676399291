<section>
    <div class="top-container bg-color-secondary">
        <h1 class="text-center text-white">A Single platform to keep everyone connected</h1>
    </div>
    <div class="container-fluid inner-section">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="inner-block align-items-center">
                    <div class="row">
                        <div class="col-sm-6 col-lg-3">
                            <div class="block-container mb-5 mb-lg-0 d-flex flex-column align-items-center">
                                <h3 class="color-primary text-center mb-2">MOTHER</h3>
                                <p class="font-13 color-light text-center mb-4">
                                    I am sure I will have a safe<br>
                                    delivery & a healthy baby, thanks<br>
                                    to the regular care and hospital<br>
                                    visits when suggested.
                                </p>
                                <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/reach/mother.png">
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="block-container mb-5 mb-lg-0 d-flex flex-column align-items-center">
                                <h3 class="color-primary text-center mb-2">HEALTH WORKER</h3>
                                <p class="font-13 color-light text-center mb-4">
                                    Visiting mothers at their<br>
                                    doorstep and keeping a tab of<br>
                                    their health has helped me in<br>
                                    touching many lives.
                                </p>
                                <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/reach/hw.png">
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="block-container mb-5 mb-sm-0 mb-lg-0 d-flex flex-column align-items-center">
                                <h3 class="color-primary text-center mb-2">DOCTOR</h3>
                                <p class="font-13 color-light text-center mb-4">
                                    I have all the records and<br>
                                    a list of high-risk mothers to<br>
                                    intervene and follow-up on<br>
                                    priority.
                                </p>
                                <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/reach/doctor.png">
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <div class="block-container d-flex flex-column align-items-center">
                                <h3 class="color-primary text-center mb-2">ADMIN</h3>
                                <p class="font-13 color-light text-center mb-4">
                                    The web portal helps me to<br>
                                    view the analytics and individual<br>
                                    performances in a simplified<br>
                                    a manner.
                                </p>
                                <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/reach/admin.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>