import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-home-offering',
  templateUrl: './home-offering.component.html',
  styleUrls: ['./home-offering.component.scss']
})
export class HomeOfferingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    new Glide('.glider__home__offering',{
      type: 'carousel',
      startAt: 0,
      perView: 3,
      bound: true,
      // autoplay: 2000,
      hoverpause: false,
      animationTimingFunc: "ease",
      animationDuration: 800,
      breakpoints: {
        1200: {
          perView: 3
        },
        992: {
          perView: 2
        },
        768: {
          perView: 2
        },
        576: {
          perView: 1
        },
      }
    }).mount({ Controls, Breakpoints });
  }

}
