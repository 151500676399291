<section id="news-section" class="bg-color-secondary-lighter">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="cards-left">
                <div class="details-block">
                    <div class="block-inner">
                        <h1 class="color-secondary mb-4">CareMother, in the press</h1>
                        <p class="color-primary mb-4">
                            We've been making a splash in the media.<br>
                            Read all the latest press articles and mentions<br>
                            about CareMother. For press opportunities,<br>
                            contact us at <a href="mailto:sales@caremother.in" class="color-secondary">sales@caremother.in</a>
                    </div>
                </div>
            </div>

            <div class="cards-right">
                <div class="cards-wrap">
                    <div class="cards-inner cards-inner-left cards-clearfix">
                        <a href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-carenx-provides-quality-healthcare-to-rural-pregnant-women-3756341.htm" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/cnbc.svg">
                                    <p class="font-15 color-primary my-4">
                                        How doto health provides quality healthcare to rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        <a href="https://indianexpress.com/article/cities/mumbai/iitian-mobile-app-to-detect-high-risk-pregnancies-emergency-room-4650290/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/indian_express.svg">
                                    <p class="font-15 color-primary my-4">
                                        Iitian's mobile app to detect high risk pregnancies
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://yourstory.com/2017/08/carenx-innovations-portable-pregnancy-care-villages/amp" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/your_story.svg">
                                    <p class="font-15 color-primary my-4">
                                        CareMother takes quality healthcare to the doorsteps of rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://mumbaimirror.indiatimes.com/mumbai/other/city-change-makers-honoured-in-uk/articleshow/64901919.cms" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/mumbai_mirror.svg">
                                    <p class="font-15 color-primary my-4">
                                        Three social entrepreneurs felicitated by British queen at Buckingham Palace in final year of Young Leaders Awards programme
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.thebetterindia.com/249309/low-cost-innovation-caremother-baby-heartbeat-marico-mumbai-ana79/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/better_india.svg">
                                    <p class="font-15 color-primary my-4">
                                        Low-Cost Innovation Helps Pregnant Women Measure Their Baby’s Heartbeat without Docs
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.business-standard.com/content/press-releases-ani/zs-announces-portable-fetal-health-assessment-device-babybeat-as-one-of-the-impactful-healthcare-solutions-in-zs-prize-121071300430_1.html" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/business_standards.svg">
                                    <p class="font-15 color-primary my-4">
                                        ZS announces portable fetal health assessment device 'BabyBeat' as one of the impactful healthcare solutions in ZS PRIZE
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.expresscomputer.in/industries/healthcare/managing-pregnancy-in-the-times-of-the-pandemic-the-digital-way/78052/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/express_computer.svg">
                                    <p class="font-15 color-primary my-4">
                                        Managing pregnancy in the times of the pandemic, the digital way
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.global-solutions-initiative.org/young-global-changers-blog/pregnancy-empower-indian-mothers/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/global_solutions.svg">
                                    <p class="font-15 color-primary my-4">
                                        Using technology to empower Indian mothers
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-carenx-provides-quality-healthcare-to-rural-pregnant-women-3756341.htm" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/the_print.svg">
                                    <p class="font-15 color-primary my-4">
                                        How doto health provides quality healthcare to rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                    </div>
                    <div class="cards-inner cards-inner-right cards-clearfix">
                        <a href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-carenx-provides-quality-healthcare-to-rural-pregnant-women-3756341.htm" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/cnbc.svg">
                                    <p class="font-15 color-primary my-4">
                                        How doto health provides quality healthcare to rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        <a href="https://indianexpress.com/article/cities/mumbai/iitian-mobile-app-to-detect-high-risk-pregnancies-emergency-room-4650290/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/indian_express.svg">
                                    <p class="font-15 color-primary my-4">
                                        Iitian's mobile app to detect high risk pregnancies
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://yourstory.com/2017/08/carenx-innovations-portable-pregnancy-care-villages/amp" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/your_story.svg">
                                    <p class="font-15 color-primary my-4">
                                        CareMother takes quality healthcare to the doorsteps of rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://mumbaimirror.indiatimes.com/mumbai/other/city-change-makers-honoured-in-uk/articleshow/64901919.cms" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/mumbai_mirror.svg">
                                    <p class="font-15 color-primary my-4">
                                        Three social entrepreneurs felicitated by British queen at Buckingham Palace in final year of Young Leaders Awards programme
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.thebetterindia.com/249309/low-cost-innovation-caremother-baby-heartbeat-marico-mumbai-ana79/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/better_india.svg">
                                    <p class="font-15 color-primary my-4">
                                        Low-Cost Innovation Helps Pregnant Women Measure Their Baby’s Heartbeat without Docs
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.business-standard.com/content/press-releases-ani/zs-announces-portable-fetal-health-assessment-device-babybeat-as-one-of-the-impactful-healthcare-solutions-in-zs-prize-121071300430_1.html" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/business_standards.svg">
                                    <p class="font-15 color-primary my-4">
                                        ZS announces portable fetal health assessment device 'BabyBeat' as one of the impactful healthcare solutions in ZS PRIZE
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.expresscomputer.in/industries/healthcare/managing-pregnancy-in-the-times-of-the-pandemic-the-digital-way/78052/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/express_computer.svg">
                                    <p class="font-15 color-primary my-4">
                                        Managing pregnancy in the times of the pandemic, the digital way
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.global-solutions-initiative.org/young-global-changers-blog/pregnancy-empower-indian-mothers/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/global_solutions.svg">
                                    <p class="font-15 color-primary my-4">
                                        Using technology to empower Indian mothers
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-carenx-provides-quality-healthcare-to-rural-pregnant-women-3756341.htm" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/the_print.svg">
                                    <p class="font-15 color-primary my-4">
                                        How doto health provides quality healthcare to rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                    </div>
                    <div class="cards-inner cards-inner-last cards-clearfix">
                        <a href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-carenx-provides-quality-healthcare-to-rural-pregnant-women-3756341.htm" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/cnbc.svg">
                                    <p class="font-15 color-primary my-4">
                                        How doto health provides quality healthcare to rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        <a href="https://indianexpress.com/article/cities/mumbai/iitian-mobile-app-to-detect-high-risk-pregnancies-emergency-room-4650290/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/indian_express.svg">
                                    <p class="font-15 color-primary my-4">
                                        Iitian's mobile app to detect high risk pregnancies
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://yourstory.com/2017/08/carenx-innovations-portable-pregnancy-care-villages/amp" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/your_story.svg">
                                    <p class="font-15 color-primary my-4">
                                        CareMother takes quality healthcare to the doorsteps of rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://mumbaimirror.indiatimes.com/mumbai/other/city-change-makers-honoured-in-uk/articleshow/64901919.cms" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/mumbai_mirror.svg">
                                    <p class="font-15 color-primary my-4">
                                        Three social entrepreneurs felicitated by British queen at Buckingham Palace in final year of Young Leaders Awards programme
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.thebetterindia.com/249309/low-cost-innovation-caremother-baby-heartbeat-marico-mumbai-ana79/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/better_india.svg">
                                    <p class="font-15 color-primary my-4">
                                        Low-Cost Innovation Helps Pregnant Women Measure Their Baby’s Heartbeat without Docs
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.business-standard.com/content/press-releases-ani/zs-announces-portable-fetal-health-assessment-device-babybeat-as-one-of-the-impactful-healthcare-solutions-in-zs-prize-121071300430_1.html" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/business_standards.svg">
                                    <p class="font-15 color-primary my-4">
                                        ZS announces portable fetal health assessment device 'BabyBeat' as one of the impactful healthcare solutions in ZS PRIZE
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.expresscomputer.in/industries/healthcare/managing-pregnancy-in-the-times-of-the-pandemic-the-digital-way/78052/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/express_computer.svg">
                                    <p class="font-15 color-primary my-4">
                                        Managing pregnancy in the times of the pandemic, the digital way
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.global-solutions-initiative.org/young-global-changers-blog/pregnancy-empower-indian-mothers/" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/global_solutions.svg">
                                    <p class="font-15 color-primary my-4">
                                        Using technology to empower Indian mothers
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                        
                        <a href="https://www.cnbctv18.com/videos/young-turks/young-turks-heres-how-carenx-provides-quality-healthcare-to-rural-pregnant-women-3756341.htm" target="_blank">
                            <div class="card" >
                                <div>
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/company/about/news/the_print.svg">
                                    <p class="font-15 color-primary my-4">
                                        How doto health provides quality healthcare to rural pregnant women
                                    </p>
                                </div>
                                <p class="font-15 color-secondary">Read Now<i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                            </div> 
                        </a>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>