<section id="team-section">
    <!-- <div class="container-block">
        <div class="bg-color-secondary-lighter right-bg"></div>
        <div class="container inner-section">
            <div class="row">
                <div class="col-12">
                    <div class="display-block d-flex flex-column-reverse flex-lg-row align-items-center justify-content-lg-between">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"  src="../../../../../assets/images/company/life/team/founders.png">
                        <div class="inner-details mb-5 mb-lg-0">
                            <div class="details-container">
                                <div class="" >
                                    <h1 class="mb-4 color-secondary">Meet The Team</h1>
                                    <p class="font-15 color-primary mb-4">
                                        We are a team of passionate individuals<br class="d-none d-lg-block">
                                        committed to provide a better pregnancy care<br class="d-none d-lg-block">
                                        for doctors, mothers and healthcare providers.<br class="d-none d-lg-block">
                                        We believe collaboration is the key to success.
                                    </p>
                                    <p class="font-15 color-primary">
                                        Each team member has a huge opportunity<br class="d-none d-lg-block">
                                        to make a big impact, and together, as a<br class="d-none d-lg-block">
                                        distributed and connected team, we support<br class="d-none d-lg-block">
                                        each other in every area of the company to<br class="d-none d-lg-block">
                                        achieve it.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
    <div class="container-fluid inner-section">
        <div class="d-flex flex-lg-row flex-column container-inner">
            <div class="teams-container bg-color-primary-light">
                <p class="font-20 text-center text-lg-left text-white my-3" >
                    Interdisciplinary 20+ member team of ,<br class="d-none d-lg-block">
                    doctors,engineers,marketing<br class="d-none d-lg-block">
                    and business personnel.
                </p>
            </div>
            <div class="glide-container">
                <div class="glide glide__testimonials_team">
                    <div class="glide__track" data-glide-el="track" >
                        <ul class="glide__slides">
                            <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/shantanu.png" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Shantanu Pathak</p>
                                                <p class="text-center font-12 color-light">Founder & CEO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/nikesh.png" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Nikesh Ingle</p>
                                                <p class="text-center font-12 color-light">Co-Founder & Head of Last Mile Care</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/tarana.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Tarana Mendiratta</p>
                                                <p class="text-center font-12 color-light">Head of Partnerships and Programs</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/pritee.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Pritee Dehukar</p>
                                                <p class="text-center font-12 color-light">Head Of Operations</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!--Slide 2-->
                            <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/ramesh.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Ramesh Mhalgi</p>
                                                <p class="text-center font-12 color-light">Software Lead</p>
                                            </div>  
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/shirish.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Shirish Wasu</p>
                                                <p class="text-center font-12 color-light">Head of Sales</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/munmun.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Munmun Ganguly</p>
                                                <p class="text-center font-12 color-light">Business Development Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/tushar.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Tushar Chavan</p>
                                                <p class="text-center font-12 color-light">Operations Executive</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/banti.png" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Banti Walke</p>
                                                <p class="text-center font-12 color-light">Regional Sales Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!--Slide 3-->
                            <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/ravi.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Ravi Chopade</p>
                                                <p class="text-center font-12 color-light">Backend Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/rahul.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Rahul Bhalerao</p>
                                                <p class="text-center font-12 color-light">Android Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/neha.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Neha Chandrakumar</p>
                                                <p class="text-center font-12 color-light">Research Associate-Devices and Regulatory</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/kajal-singh.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Kajal Singh</p>
                                                <p class="text-center font-12 color-light">Software Tester</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!--Slide 4-->
                            <!-- <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/riken.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Riken Joshi</p>
                                                <p class="text-center font-12 color-light">Regional Sales Manager</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/saurabh.jpg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Saurabh Karnawat</p>
                                                <p class="text-center font-12 color-light">Regional Sales Manager </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> -->

                            <!--Slide 5-->
                            <!-- <li class="glide__slide">
                                <div class="row slide-inner justify-content-center justify-content-lg-start">
                                    <div class="col-6 col-sm-3 card-container">
                                        <div class="card mb-3 mb-sm-none">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/ganesh.jpeg" class="card-img-top">
                                            <div class="card-body">
                                                <p class="text-center color-primary">Ganesh Bhange</p>
                                                <p class="text-center font-12 color-light">Digital Marketing Executive</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                    
                    <div class="glide__arrows d-none d-lg-block" data-glide-el="controls">
                        <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                        </button>
                        <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </button>
                    </div>
                    
                    <div class="glide__bullets d-block d-lg-none" data-glide-el="controls[nav]">
                        <button class="glide__bullet" data-glide-dir="=0"></button>
                        <button class="glide__bullet" data-glide-dir="=1"></button>
                        <button class="glide__bullet" data-glide-dir="=2"></button>
                        <button class="glide__bullet" data-glide-dir="=3"></button>
                    </div>
                </div>                    
            </div>
        </div>
    </div>
</section>