<section id="partners-section">
    <div class="container inner-section">
        <div class="row">
            <div class="text-center col-12" >
                <h1 class="color-secondary">Our Supporters and Partners</h1>
            </div>
        </div>
        <div class="row justify-content-center mt-5">
            
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-36.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-37.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-43.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-44.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-45.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-50.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-54.svg">
            </div>
            <!--<div class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/partners/logos-38.svg">
            </div>-->
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-41.svg">
            </div>
            
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-46.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-39.svg">
            </div>
            
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-48.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-49.svg">
            </div>
            <!--<div class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/partners/logos-47.svg">
            </div>-->
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-51.svg">
            </div>
            
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-52.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-55.svg">
            </div>
            <div  class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/partners/logos-40.svg">
            </div>
            <!--<div class="col-lg-3 col-md-4 col-6 mb-4 partners-img d-flex align-items-center justify-content-center">
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/partners/logos-57.svg">
            </div>-->
            
        </div>
        <div class="row justify-content-center mt-5">
            <a routerLink="/contact" color="secondary" button-filled>Get In Touch</a>
        </div>
    </div>
</section>