import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-awards',
  templateUrl: './home-awards.component.html',
  styleUrls: ['./home-awards.component.scss']
})
export class HomeAwardsComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() { }

  navigateToAwards(){
    this.router.navigate(['/company/about-us']);
  }

}
