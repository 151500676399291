<section class="bg-color-primary-lighter">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex align-items-center justify-content-center flex-column flex-lg-row justify-content-lg-between">
                    <div class="mb-4 mb-md-0 details-container" >
                        <p class="text-white mb-3">How We Work?</p>
                        <h1 class="text-white mb-4">
                            At CareMother, we put people at the centre <br>
                            of everything we do
                        </h1>
                        <div>
                            <a routerLink="/company/careers" color="white" button-filled>Explore Career</a>            
                        </div>
                    </div>
                    <!-- <img alt="Life at CareMother" src="../../../../../assets/images/company/life/intro/life-img.png"> -->
                </div>
            </div>
        </div>
    </div>
</section>