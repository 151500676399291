<section id="offering-section">
    <div class="container inner-section">
        <div class="row mb-5" >
            <div class="col-12 text-center">
                <h1 class="color-secondary">A Compact & Portable Pregnancy Care Kit</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="product-container col-md-6 col-lg-4 mb-4 mb-lg-0">
                <div class="card" >
                    <div class="card-header py-4 bg-color-secondary">
                        <p class="text-center text-white">ANTENATAL CARE KIT</p>
                    </div>
                    <div class="card-body">
                        <div class="img-container d-flex justify-content-center align-items-center">
                            <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/components/Kit-01.png">
                        </div>
                        <p class="text-center font-14 color-light">
                            Smartphone integrated portable <br>
                            diagnostic device
                        </p>
                    </div>
                </div>
            </div>
            <div class="product-container col-md-6 col-lg-4 mb-4 mb-lg-0">
                <div class="card" >
                    <div class="card-header py-4 bg-color-secondary">
                        <p class="text-center text-white">MOBILE APPLICATION</p>
                    </div>
                    <div class="card-body">
                        <div class="img-container d-flex justify-content-center align-items-center">
                            <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/components/mobile-01.png">
                        </div>
                        <p class="text-center font-14 color-light">
                            Monitoring, reporting & decision support<br>
                            for high-risk pregnancy follow-ups
                        </p>
                    </div>
                </div>
            </div>
            <div class="product-container col-md-6 col-lg-4 mb-4 mb-lg-0">
                <div class="card" >
                    <div class="card-header py-4 bg-color-secondary">
                        <p class="text-center text-white">WEB PORTAL</p>
                    </div>
                    <div class="card-body">
                        <div class="img-container d-flex justify-content-center align-items-center">
                            <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../../assets/images/products/anandimaa/components/Dashboard-01.png" id="dashboard-img">
                        </div>
                        <p class="text-center font-14 color-light">
                            A one click management and analysis<br>
                            platform with MIS
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-color-secondary-lighter">
        <div class="container inner-section">
            <div class="row explore-container" >
                <div class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between">
                    <h1 class="color-primary text-center text-lg-left font-28 mb-4 mb-lg-0">
                        An AI-enabled high-risk pregnancy management service <br>
                        that ensures healthier pregnancies.
                    </h1>
                    <button (click)="scrollTo()" color="secondary" button-filled>
                        How It Works?</button>
                </div>
            </div>
        </div>
    </div>
</section>
