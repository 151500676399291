<section id="rolling-counter">
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div class="bg-color-secondary counter-container">
                    <div class="row" >
                        <div class="col-6 col-lg-3 mb-3 mb-lg-0 text-center">
                            <div class="single_counter happy_counter contact_icon">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/icons/home/hospitals - Copy.svg">
                                <h1 class="text-white mt-3" id="counter_num">{{hospitalCount}}+</h1>
                                <p class="text-white">Hospitals</p>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 mb-3 mb-lg-0 text-center">
                            <div class="single_counter happy_counter contact_icon">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/icons/home/mother - Copy.svg">
                                <h1 class="text-white mt-3" id="counter_num">{{motherCount}}+</h1>
                                <p class="text-white">Mothers</p>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 mb-3 mb-lg-0 text-center">
                            <div class="single_counter happy_counter contact_icon">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/icons/home/states - Copy.svg">
                                <h1 class="text-white mt-3" id="counter_num">{{stateCount}}+</h1>
                                <p class="text-white">States</p>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 mb-3 mb-lg-0 text-center">
                            <div class="single_counter happy_counter contact_icon">
                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/icons/home/globe - Copy.svg">
                                <h1 class="text-white mt-3" id="counter_num">{{countryCount}}</h1>
                                <p class="text-white">Countries</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</section>

