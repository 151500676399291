import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

interface Utility{
    anandiMaaDocAndHW:number,
    anandiMaaInCountries:number,
    anandiMaaRollingCount:{
      mothersReached:number,
      partnersJoined:number,
      statesCovered:number,
      villageCovered:number,
      visitsMade:number
    },
    fetomaxTotalMothers:string,
    fetomaxTotalStates:number,
    homeRollingCount:{
      totalCountries:number,
      totalMothers:number,
      totalOrgs:number,
      totalStates:number
    },
    totalDoctors:number
}

@Injectable({
  providedIn: 'root'
})
export class LandingDataService {
  private platformNumbers = new BehaviorSubject<Utility>(undefined);
  currentPlatformNumbers = this.platformNumbers.asObservable();
  constructor(private afs: AngularFirestore) { }

  setPlatformNumbers(numberObj: Utility) {
    this.platformNumbers.next(numberObj);
  }

  getUtility(){
    return this.afs.collection('websiteConfig').doc('utility').valueChanges();
  }

  getAnnouncement(){
    return this.afs.collection('websiteConfig').doc('announcement').valueChanges();
  }

  getStayTuned(){
    return this.afs.collection('stayTuned',ref=>
      ref.limit(3).orderBy('createdOn','desc')
    ).valueChanges();
  }
}
