import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, AfterContentInit {

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta) {
    this.title.setTitle('NST/CTG Machine Manufacturer in India. 9930990900');
    this.meta.updateTag({ name: 'description', content: "CareMother FetoMax is a Smartphone based digital, wireless, portable NST/CTG machine, India's most advanced Fetal Heart Monitor in Pune, Mumbai, India. 9930990900" })
    this.meta.updateTag({ name: 'keywords', content: "NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, India's most advanced Fetal Heart Monitor, FetoMax, CareMother" })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  ngAfterContentInit(): void {
    
    if(this.router.url.includes('#publications')){
      setTimeout(()=>{
        let element = document.getElementById('publications-section');
        element.scrollIntoView({behavior:'smooth'});
      },500)
    }

    if(this.router.url.includes('#awards&recognitions')){
      setTimeout(()=>{
        let element = document.getElementById('awards-section');
        element.scrollIntoView({behavior:'smooth'});
      },500)
    }

    if(this.router.url.includes('#supporters&partners')){
      setTimeout(()=>{
        let element = document.getElementById('partners-section');
        element.scrollIntoView({behavior:'smooth'});
      },500)
    }

    if(this.router.url.includes('#newsroom')){
      setTimeout(()=>{
        let element = document.getElementById('news-section');
        element.scrollIntoView({behavior:'smooth'});
      },500)
    }
  }
}
