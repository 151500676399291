<section class="bg-color-primary-light">
    <div class="container-fluid  inner-section">
        <div class="row">
            <div class="col-12 col-md-6 px-0">
                <div class="left-block">
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/components/feto-05.png">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="right-block d-flex align-items-center justify-content-center">
                    <div>
                        <h1 class="mb-4 text-center text-lg-left text-white">
                            Reinvent the fetal heart<br>
                            monitoring experience with<br>
                            CareMother FetoMax
                        </h1>
                        <div class="btn-grp d-flex flex-column align-items-center flex-lg-row">
                            <a href="../../../../../assets/docs/FetoMax brochure.pdf" color="white" button-outlined download>Download Brochure</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>