<section id="home-updates" class="">
    <div class="container inner-section">
        <div class="row justify-content-center" >
            <div class="col-md-12 mb-5">
                <h1 class="text-center color-secondary">Stay tuned! A lot is happening at CareMother</h1>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let stay of stayTunedList; let i = index" class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100" >
                    <div class="inner-img">
                        <img [alt]="stay.title" class="" [src]="stay.media.downloadUrl">
                    </div>
                    <div class="card-body">
                        <p class="font-17 color-secondary mb-4">{{stay.title}}</p>
                        <p class="font-14">
                            {{stay.description}}
                        </p>
                    </div>
                    <div class="card-footer bg-color-secondary"></div>
                </div>
            </div>
            <!-- <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100" >
                    <div class="inner-img">
                        <img alt="CareMother FetoMax Won the “Best Stall Award”" class="" src="../../../../assets/images/home/updates/image 1.jpeg">
                    </div>
                    <div class="card-body">
                        <p class="font-17 color-secondary mb-4">CareMother FetoMax Won the  <br class="d-none d-md-block d-lg-none d-lg-block">“Best Stall Award”</p>
                        <p class="font-14">
                            CareMother FetoMax (A Wireless-Portable NST/CTG Device) exhibited 
                            at Nagpur Obstetrics And Gynecological Society - GYNECON 2021 won the
                            best stall award with a huge turnout of doctors.
                        </p>
                    </div>
                    <div class="card-footer bg-color-secondary"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100" >
                    <div class="inner-img">
                        <img alt="CareMother AnandiMaa has reached 12+ States in India." class="" src="../../../../assets/images/home/updates/image 2.jpg">
                    </div>
                    <div class="card-body">
                        <p class="font-17 color-secondary mb-4">CareMother AnandiMaa has reached 12+ States in India.</p>
                        <p class="font-14">
                            CareMother Anandimaa - an AI enabled, high risk pregnancy management platform 
                            has now reached 12+ states with the support of 1200+ health workers and doctors 
                            who are caring for more than 65,000+ expecting mothers.
                        </p>
                    </div>
                    <div class="card-footer bg-color-secondary"></div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-4">
                <div class="card o-hidden h-100" >
                    <div class="inner-img">
                        <img alt="CareMother in conversation with Oxford Policy Management." class="" src="../../../../assets/images/home/updates/image 3.jpg">
                    </div>
                    <div class="card-body">
                        <p class="font-17 color-secondary mb-4">CareMother in conversation with  <br class="d-none d-md-block d-lg-none d-lg-block">Oxford Policy Management.</p>
                        <p class="font-14">
                            Oxford Policy Management invited Shantanu Pathak (CEO) to share his views and 
                            efforts on the last mile and consultation on designing Home Based Pregnancy Care 
                            with several other great folks.
                        </p>
                    </div>
                    <div class="card-footer bg-color-secondary"></div>
                </div>
            </div> -->
        </div>
    </div>
</section>
