import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-babybeat-faq',
  templateUrl: './babybeat-faq.component.html',
  styleUrls: ['./babybeat-faq.component.scss']
})
export class BabybeatFaqComponent implements OnInit {

  filters = [
    {label: "General FAQs", value: 'general'},
    {label: "Troubleshooting FAQs", value: 'troubleshoot'}
  ]

  generalFaq = [
    {
      title: "What is fetal heart monitoring?",
      body: `Fetal heart rate monitoring measures your baby’s heart rate (or FHR / fetal heart rate) and
      rhythm. This allows your doctor to see how your baby is doing. Fetal heart rate is a good
      indicator of your baby’s overall health.`
    },
    {
      title: "What is the importance of fetal heart monitoring?",
      body: `Fetal Heart Monitoring reflects overall health of the baby, particularly the level of oxygen supply
      to the baby. Abnormal fetal heart rate, especially on multiple occasions maybe a sign of poor
      oxygen supply, which can lead to complications in the delivery and after-delivery or post-natal
      period. One such complication is called ‘hypoxia’ that can cause difficulty in breathing for the
      baby after delivery. This may lead to intensive care admission, further prolonging the hospital
      stay. Timely monitoring of FHR is critical to enable the doctor take decisions during the
      pregnancy itself, and avoid such complications.`
    },
    {
      title: "Is fetal heart monitoring safe?",
      body: `Yes. Radiation is not used for this test. The transducer that comes with the device usually causes
      no discomfort. You may find the elastic belts that are used to hold the transducers in place
      slightly uncomfortable. But these can be readjusted as needed. You must lie still during the
      entire fetal heart rate monitoring process. If in labour, you may need to stay in bed during the
      time of fetal monitoring.`
    },
    {
      title: "How can I do fetal heart monitoring at home?",
      body: `“BabyBeat” is an example of a home-based fetal heart monitoring device.`,
      para: [
        `Each BabyBeat kit is given to the mother by her attending doctor (obstetrician) or delivered by
        BabyBeat delivery partner. This contains a belt for strapping the device over the abdomen,
        charging cables, ultrasound doppler based wireless device, ultrasound gel for adhesion, and a
        user manual. The mother is also provided with an easy-to-use mobile application. The
        application guides the mother on starting the NST to listen to the baby’s heartbeat and share the
        report with her doctor. The device connects with the mobile app with Bluetooth.`
      ]
    },
    {
      title: "How many times I should do fetal heart monitoring at home? And in which weeks?",
      para: [
        `Please follow your doctor’s advice on the number of times you should do fetal heart monitoring.
        It should not be done again and again out of curiosity. This is a test with a very specific purpose
        and your doctor will guide you on the frequency of use.`,
        `The purpose of FHR monitoring is to monitor your baby’s health if you have a high-risk
        pregnancy; for example, if you have diabetes or high blood pressure, or your baby is not
        developing or growing as it should. FHR monitoring is also used to check how medicines used to
        prevent labour from starting too early, or preterm labor, are affecting your baby.`,
        `FHR monitoring is done usually during late pregnancy or labour. You may be asked to do it after
        32 weeks of pregnancy.`
      ]
    },
    {
      title: "How would I know if the result of fetal heart monitoring is normal?",
      body: `The average (normal) fetal heart rate is between 110 and 160 beats per minute. It can vary by 5
      to 25 beats per minute.`
    },
    {
      title: "What should I do if the result is not normal?",

      body: `BabyBeat allows you to instantly share your FHR results with your doctor. The doctor and/or
      nurse will let you know the next steps that should be taken if the report is not normal.`,

      listHead:`If your baby's heart rate is persistently flat, slow or fast, your doctor may consider the following
      steps:`,

      listUnordered:[
        `asking you to change your position`,
        `giving you more fluids through an IV, if he/she decides to admit you for some time`,
        `giving you supplemental oxygen.`,
        `stopping oxytocin if you are in labour and if your labour is being induced or augmented.`,
        `giving you medication to relax the uterus or decrease the contractions of the uterus.`
      ],
      
      listFoot:`If your baby's heart rate continues to be abnormal even after these steps or worsens, your
      doctor may consider an assisted delivery or a C-section.`
    },
    {
      title: "Should I worry if FHR is not normal?",
      para: [
        `Some dips in a baby's heart rate are NORMAL. Also, some heart rate fluctuations are healthy and
        necessary. For example, when your baby moves, the heart rate should go up, just as your heart rate
        does when you exercise.`,
        `If you listened to your baby’s heartbeat for some time and it suddenly stops, then don’t panic. Most
        likely, the transducer on your abdomen has shifted out of place and lost contact with the heartbeat.
        Readjust the transducer or repeat the process after resting for 5-10 minutes, or call the nurse to get
        her help over the phone in performing the process.`
      ]
    },
    {
      title: "If I am anxious or unwell, should I do fetal heart monitoring?",
      body: `Ideally, you should not. Your anxiety, fever, sickness, or tiredness will have an impact on FHR in a
      way that is biased. You will not get a correct FHR reading, or a reading that is due to the baby’s own
      condition. Therefore, first, address your illness, take rest and when you are calm and comfortable,
      do the FHR testing.`
    },
    {
      title: "What are the things to keep in mind before I do fetal heart monitoring?",
      body: `Tell your healthcare provider if you are sensitive or allergic to any medicines, latex, tape, or such
      materials used for monitoring. Rest and staying calm before the monitoring is essential – in this
      sense, it is just like blood pressure monitoring, where BP can shoot up or decrease depending on
      your physical and mental state and worry you more! You may have a meal an hour before the
      test, as food intake is known to make the baby more active, for a more responsive FHR. So, you
      can consider to do the monitoring after a major meal. You don't need any special care after fetal
      heart monitoring. You may go back to your normal diet and activity, unless your doctor has
      advised special instructions.`
    }
  ]

  troubleShootFaq = [
    {
      title: "I can't find the CareMother app in my app store or play store.",
      body: "Ensure that your phone is from India. Please contact our support team if you brought this phone from another country.",
      img: "assets/images/faqs/faq1.png"
    },
    {
      title: "I don't have a QR code for my device for the hospital.",
      body: "Please contact our support team."
    },
    {
      title: "The probe I'm using to monitor the baby's heartbeat cannot be positioned correctly. What is the best place to put it?",
      body: "refer to fetal heart position reference poster",
      images: [
        'assets/images/products/babybeat/faq/faq3.1.png',
        'assets/images/products/babybeat/faq/faq3.2.png',
        'assets/images/products/babybeat/faq/faq3.3.png'
      ]
    },
    {
      title: "My Doppler looks active but is not tracing the baby's heartbeat on graph. ",
      body: "Before you begin the test, apply the jelly on your tummy. As soon as it detects your FHR, click on the ‘start test’ button.",
      images: [
        'assets/images/products/babybeat/faq/faq4.1.png',
        'assets/images/products/babybeat/faq/faq4.2.png'
      ]
    },
    {
      title: "I am unable to share my report with my doctor.",
      body: "Connect your mobile phone to the internet and select the share option.",
      images: [
        'assets/images/products/babybeat/faq/faq5.1.png',
        'assets/images/products/babybeat/faq/faq5.2.png'
      ]
    },
    {
      title: "I'm having trouble connecting to Doppler.",
      para: [
        `Step 1: Turn off the Doppler and close the application.`,
        `Step 2: Clean up your phone's recent tabs.`,
        `Step 3:  Now, switch on the doppler, open the application and try again.`

      ],
      images: [
        'assets/images/products/babybeat/faq/faq6.png'
      ]
    },
    {
      title: "We are unable to scan the QR code as it shows ‘Doctor not associated",
      body: "Please contact our support team.",
      
    },
    {
      title: "It is asking for a QR code to be scanned.",
      body: "Scan the QR code provided by the delivery person."
    },
    {
      title: "For marking movements, where should I click?",
      body: "Please click on the movement button.",
      images: [
        'assets/images/products/babybeat/faq/faq9.1.png',
        'assets/images/products/babybeat/faq/faq9.2.png'
      ]
      
    }
  ]
  faqList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedFilter: string = "general";

  constructor() { }

  ngOnInit() {
    this.faqList.next([...this.generalFaq])
  }

  applyFilter(event){
    if(event){
      if(event.value == "general"){
        this.faqList.next([...this.generalFaq])
      }
      if(event.value == "troubleshoot"){
        this.faqList.next([...this.troubleShootFaq])
      }
    }
  }

}
