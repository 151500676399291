<section>
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div>
                    <h1 class="color-bb-secondary text-center">
                        Track Your Baby’s Fetal Heart Rate
                    </h1>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/feature/medical-grade.svg">
                    <h2 class="mb-2 text-center color-primary font-22">Medical Grade</h2>
                    <p class="color-light text-center font-16">Meets medical gold standards<br class="small-screen d-sm-none d-md-block">
                        with better accuracy</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/feature/portable.svg">
                    <h2 class="mb-2 text-center color-primary font-22">Compact & Portable</h2>
                    <p class="color-light text-center font-16">Easy to use, carry and comes with<br class="small-screen d-sm-none d-md-block">
                        rechargeable batteries</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/feature/safe.svg">
                    <h2 class="mb-2 text-center color-primary font-22">Safe & Reliable</h2>
                    <p class="color-light text-center font-16">Certified & proven CE, ISO 13485<br class="small-screen d-sm-none d-md-block">
                        Safe & Reliable</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/feature/cloud.svg">
                    <h2 class="mb-2 text-center color-primary font-22">Cloud Based</h2>
                    <p class="color-light text-center font-16">Live data accessible to doctors<br class="small-screen d-sm-none d-md-block">
                        for remote monitoring</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/feature/app.svg">
                    <h2 class="mb-2 text-center color-primary font-22">App Based</h2>
                    <p class="color-light text-center font-16">India’s most trusted pregnancy<br class="small-screen d-sm-none d-md-block">
                        care app: CareMother</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/feature/accurate.svg">
                    <h2 class="mb-2 text-center color-primary font-22">Accurate</h2>
                    <p class="color-light text-center font-16">Accuracy & efficiency of doppler<br class="small-screen d-sm-none d-md-block">
                        using latest optimizations.</p>
                </div>
            </div>
        </div>
    </div>
</section>