import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-fetosense-videos',
  templateUrl: './fetosense-videos.component.html',
  styleUrls: ['./fetosense-videos.component.scss']
})
export class FetosenseVideosComponent implements OnInit {
  safeURL: any;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/BPis8PALD_o');
  }

}
