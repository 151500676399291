<section id="anandimaa-counter">
    <div class="container inner-section">
        <div class="row justify-content-center">
            <div class="col-sm-6 col-md-4 col-lg">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="mb-4" src="../../../../../assets/images/products/anandimaa/counter/visits.svg">
                    <h1 class="mb-2 text-center color-primary">{{visitsCount}}+</h1>
                    <p class="color-light text-center font-16">Visits Made</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="mb-4" src="../../../../../assets/images/products/anandimaa/counter/mothers.svg">
                    <h1 class="mb-2 text-center color-primary">{{mothersCount}}+</h1>
                    <p class="color-light text-center font-16">Mothers Reached</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="mb-4" src="../../../../../assets/images/products/anandimaa/counter/villages.svg">
                    <h1 class="mb-2 text-center color-primary">{{villagesCount}}+</h1>
                    <p class="color-light text-center font-16">Villages Covered</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="mb-4" src="../../../../../assets/images/products/anandimaa/counter/states.svg">
                    <h1 class="mb-2 text-center color-primary">{{statesCount}}+</h1>
                    <p class="color-light text-center font-16">States Covered</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg">
                <div class="d-flex flex-column align-items-center mb-5">
                    <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="mb-4" src="../../../../../assets/images/products/anandimaa/counter/partners.svg">
                    <h1 class="mb-2 text-center color-primary">{{partnersCount}}+</h1>
                    <p class="color-light text-center font-16">Partners Joined</p>
                </div>
            </div>
        </div>
    </div>
</section>