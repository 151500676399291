<section>
    <div class="right-bg d-none d-lg-block"></div> <!--bg-color-primary-light-->
    <div class="container-fluid inner-section">
        <div class="col-12">
            <div class="title" >
                <h1 class="color-secondary mb-5 text-left">
                    {{infoStmt}} <br>
                    digital monitoring
                </h1>
            </div>
            <div class="d-flex flex-column-reverse flex-lg-row align-items-center justify-content-between">
                <div class="details-container">
                    <div class="col-12" >
                        <div class="d-flex align-items-center mb-3">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="digital-icon mr-3" src="../../../../assets/images/home/human-element/section icon-22.png">
                            <h2 class="color-secondary">Convenience</h2>
                        </div>
                        <p class="color-primary">for all stakeholders with wireless and remote <br>
                                    monitoring solutions</p>
                        <hr>
                    </div>
                    <div class="col-12" >
                        <div class="d-flex align-items-center mb-3">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="digital-icon mr-3" src="../../../../assets/images/home/human-element/section icon-23.png">
                            <h2 class="color-secondary">Time Saving</h2>
                        </div>
                        <p class="color-primary">of at least 5 minutes per fetal monitoring test with <br>
                            AI powered decision support tools</p>
                            <hr>
                    </div>
                    <div class="col-12" >
                        <div class="d-flex align-items-center mb-3">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="digital-icon mr-3" src="../../../../assets/images/home/human-element/section icon-24.png">
                            <h2 class="color-secondary">Patient Engagement</h2>
                        </div>
                        <p class="color-primary">increased with personalized content and report <br>
                                sharing with digital approach</p>
                        <hr>
                    </div>
                    <div class="col-12" >
                        <div class="d-flex align-items-center mb-3">
                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="digital-icon mr-3" src="../../../../assets/images/home/human-element/section icon-25.png">
                            <h2 class="color-secondary">One Stop Digital Tool For Clinic/Doctor</h2>
                        </div>
                        <p class="color-primary">giving access to live reports from devices, patient <br>
                                engagement and care plan generation</p>
                    </div>
                </div>
                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="digital-image mb-5 mb-lg-0"  src="../../../../assets/images/home/human-element/digital-image.png">
            </div>
        </div>
    </div>
</section>