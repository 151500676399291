import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-videos',
  templateUrl: './home-videos.component.html',
  styleUrls: ['./home-videos.component.scss']
})
export class HomeVideosComponent implements OnInit {
  safeURL1: any;
  safeURL2: any;
  safeURL3: any;
  safeURL4: any;

  constructor(private _sanitizer: DomSanitizer) {
    this.safeURL1 = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/k8NYUuUVb9U');
    this.safeURL2 = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Lmam4q240ic');
    this.safeURL3 = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/IqO1V-5ZSRs');
    this.safeURL4 = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/BPis8PALD_o');
  }

  ngOnInit() {
    new Glide('.glide__videos',{
      type: 'carousel',
      startAt: 0,
      perView: 1,
      gap: 30,
      focusAt: 'center'
    }).mount({ Controls, Breakpoints })
  }

}
