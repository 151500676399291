import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  isFetosense: boolean = false;
  constructor() {
    
  }

  ngOnInit() {
  }

  navList = [
    {
      title: 'Company',
      children: [
        {
          title: 'About Us',
          route: '/company/about-us'
        },
        {
          title: 'Life At CareMother',
          route: '/company/life-at-caremother'
        },
        /*{
          title: 'Blogs',
          route: 'https://blogs.caremother.in/'
        },*/
      ]
    },
    {
      title: 'Offerings',
      children: [
        {
          title: 'BabyBeat',
          route: '/offering/babybeat'
        },
        {
          title: 'FetoMax',
          route: '/offering/fetomax'
        },
        {
          title: 'FetoTwin',
          route: '/offering/feto-twin'
        },
        {
          title: 'FetoMini',
          route: '/offering/feto-mini'
        },
        {
          title: 'AnandiMaa',
          route: '/offering/anandimaa'
        },
      ]
    },
    {
      title: 'Connect',
      children: [
        {
          title: 'Contact',
          route: '/contact'
        },
        {
          title: 'Career',
          route: '/company/careers'
        },
      ]
    },
  ]

}
