import { Component, OnInit, HostListener, AfterContentInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';


@Component({
  selector: 'app-rolling-counter',
  templateUrl: './rolling-counter.component.html',
  styleUrls: ['./rolling-counter.component.scss']
})
export class RollingCounterComponent implements OnInit, AfterContentInit {

  motherCount:number = 0;
  hospitalCount:number = 0;
  stateCount:number = 0;
  countryCount:number = 0;


  totalMotherCount:number = 500000;
  totalHospitalCount:number = 750;
  totalStateCount:number = 25;
  totalCountryCount:number = 5;

  countryCountStop: any;
  stateCountStop: any;
  hospitalCounterStop: any;
  motherCounterStop: any;

  constructor(private landingService:LandingDataService) {
   this.landingService.currentPlatformNumbers.subscribe(data=>{
      if(data && data.homeRollingCount){
        this.totalCountryCount=data.homeRollingCount.totalCountries;
        this.totalHospitalCount=data.homeRollingCount.totalOrgs;
        this.totalMotherCount=data.homeRollingCount.totalMothers;
        this.totalStateCount=data.homeRollingCount.totalStates;
      }
      
    });
   }
  ngAfterContentInit(): void {
    var target = document.getElementById('rolling-counter');
    console.log(target.offsetTop + 130, window.innerHeight)
    if(window.innerHeight >= target.offsetTop){
      if(this.motherCount != this.totalMotherCount && this.motherCount < (this.totalMotherCount-299)){
        this.motherCount = this.totalMotherCount-299;
      }else if(this.motherCount == this.totalMotherCount){
        this.motherCount = this.totalMotherCount;
      }

      this.motherCounterStop = setInterval(()=>{
        
        if(this.motherCount < this.totalMotherCount){
          this.motherCount++;
        }
        if(this.motherCount >= this.totalMotherCount){
          clearInterval(this.motherCounterStop);
        }
      },50)

      if(this.hospitalCount != this.totalHospitalCount && this.hospitalCount < (this.totalHospitalCount-299)){
        this.hospitalCount = (this.totalHospitalCount-299);
      }else if(this.hospitalCount == this.totalHospitalCount){
        this.hospitalCount = this.totalHospitalCount;
      }
      
      this.hospitalCounterStop = setInterval(()=>{
        if(this.hospitalCount < this.totalHospitalCount){
          this.hospitalCount++;
        }
        if(this.hospitalCount >= this.totalHospitalCount){
          clearInterval(this.hospitalCounterStop);
        }
      },50)
      
      this.stateCountStop = setInterval(()=>{
        if(this.stateCount < this.totalStateCount){
          this.stateCount++;
        }
        
        if(this.stateCount >= this.totalStateCount){
          clearInterval(this.stateCountStop);
        }
      },200)

      this.countryCountStop = setInterval(()=>{
        if(this.countryCount < this.totalCountryCount){
          this.countryCount++;
        }
        if(this.countryCount >= this.totalCountryCount){
          clearInterval(this.countryCountStop);
        }
      },400)
    }
  }

  ngOnInit() { }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
        
      var target = document.getElementById('rolling-counter');
      const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
      if(offset >= target.offsetHeight && offset <= target.offsetTop) {

        if(this.motherCount != this.totalMotherCount && this.motherCount < (this.totalMotherCount-299)){
          this.motherCount = this.totalMotherCount-299;
        }else if(this.motherCount == this.totalMotherCount){
          this.motherCount = this.totalMotherCount;
        }
  
        this.motherCounterStop = setInterval(()=>{
          if(this.motherCount < this.totalMotherCount){
            this.motherCount++;
          }
          if(this.motherCount >= this.totalMotherCount){
            clearInterval(this.motherCounterStop);
          }
        },50)
  
        if(this.hospitalCount != this.totalHospitalCount && this.hospitalCount < (this.totalHospitalCount-299)){
          this.hospitalCount = (this.totalHospitalCount-299);
        }else if(this.hospitalCount == this.totalHospitalCount){
          this.hospitalCount = this.totalHospitalCount;
        }
        
        this.hospitalCounterStop = setInterval(()=>{
          if(this.hospitalCount < this.totalHospitalCount){
            this.hospitalCount++;
          }
          if(this.hospitalCount >= this.totalHospitalCount){
            clearInterval(this.hospitalCounterStop);
          }
        },50)
        
        this.stateCountStop = setInterval(()=>{
          if(this.stateCount < this.totalStateCount){
            this.stateCount++;
          }
          
          if(this.stateCount >= this.totalStateCount){
            clearInterval(this.stateCountStop);
          }
        },200)
  
        this.countryCountStop = setInterval(()=>{
          if(this.countryCount < this.totalCountryCount){
            this.countryCount++;
          }
          if(this.countryCount >= this.totalCountryCount){
            clearInterval(this.countryCountStop);
          }
        },400)
      }
    }
  }

}
