import { Component, OnInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';

@Component({
  selector: 'app-fetosense-stats',
  templateUrl: './fetosense-stats.component.html',
  styleUrls: ['./fetosense-stats.component.scss']
})
export class FetosenseStatsComponent implements OnInit {
  fetoStates:string='20+';
  fetoMothers:string='2L';
  constructor(private landingService:LandingDataService) { }

  ngOnInit() {
    this.landingService.currentPlatformNumbers.subscribe(data=>{
      if(data){
        this.fetoStates=String(data.fetomaxTotalStates+'+');
        this.fetoMothers=data.fetomaxTotalMothers
      }
    });
  }

}
