<section class="bg-color-primary-light">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex flex-lg-row align-items-lg-end flex-column align-items-center">
                    <div class="display-block">
                        <p class="color-primary mb-3" >Why Choose Us?</p>
                        <h1 class="color-primary mb-5" >
                            {{infoStmt}}
                        </h1>
                        <div class="row">
                            <div class="col-sm-4 details-container">
                                <div class="card" >
                                    <div class="card-body">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/products/intro/offering-01.png">
                                        <h4 class="my-3 color-primary">Digital Tools</h4>
                                        <p class="font-15 text-center color-light">
                                            enable access to <br>
                                            live reports from devices,<br> 
                                            and engage patients for <br>
                                            doctors/clinics
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 details-container">
                                <div class="card" >
                                    <div class="card-body">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/products/intro/offering-02.png">
                                        <h4 class="my-3 color-primary">Time-Saving</h4>
                                        <p class="font-15 text-center color-light">
                                            with Al powered <br>
                                            decision support tools, <br>
                                            advanced offerings and <br>
                                            digital approach
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 details-container">
                                <div class="card" >
                                    <div class="card-body">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/products/intro/offering-03.png">
                                        <h4 class="my-3 color-primary">Convenient</h4>
                                        <p class="font-15 text-center color-light">
                                            for all stakeholders <br>
                                            with wireless <br>
                                            and real-time remote <br>
                                            monitoring solutions
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"  src="../../../../assets/images/products/intro/doctors-indian.png">
                </div>
            </div>
        </div>
    </div>
</section>