import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-babybeat-intro',
  templateUrl: './babybeat-intro.component.html',
  styleUrls: ['./babybeat-intro.component.scss']
})
export class BabybeatIntroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // Initialize Glide for the main section
    new Glide('.inner-section .glide__slider', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      gap: 0,
      autoplay: 5000,
      hoverpause: false,
    }).mount({ Controls, Breakpoints });

    // Initialize Glide for the small-product-image section
    new Glide('.small-product-image .glide__slider', {
      type: 'slider',
      startAt: 0,
      perView: 1,
      gap: 0,
      autoplay: 5000,
      hoverpause: false,
    }).mount({ Controls, Breakpoints });
  }

  scrollTo(sectionName){
    let element = document.getElementById(sectionName);
    element.scrollIntoView({behavior:'smooth'});
  }

}
