<section>
    <div class="left-bg bg-color-bb d-none d-lg-block">
        <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../../assets/images/products/fetosense/components/feto-05.png">
    </div>
    <div class="container-fluid inner-section">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="inner-block d-flex flex-lg-row flex-column justify-content-between">
                    <div class="image-container mb-5 mb-lg-0 d-flex align-items-center justify-content-center">
                        <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/components/components.png">
                    </div>
                    
                    <div class="details-container d-flex flex-column align-items-center align-items-lg-start justify-content-center mb-4 mb-md-0" >
                        <h1 class="color-bb-secondary mb-4 text-lg-left text-center">
                            A Fetal Heart Rate Monitor For<br class="d-none d-sm-block">
                            Expecting Mothers</h1>
                        <p class="mb-4 font-18 text-lg-left text-center">
                            BabyBeat comes with a supporting app that helps to find<br class="d-none d-md-block d-lg-none d-xl-block">
                            and track your baby’s heartbeat. With BabyBeat, expecting<br class="d-none d-md-block d-lg-none d-xl-block">
                            moms can easily perform NST (Fetal Heart Rate Test) at home<br class="d-none d-md-block d-lg-none d-xl-block">
                            and share the reports with their doctors if required.
                        </p>
                        <p class="mb-4 font-18 text-lg-left text-center">
                            Pregnancy can be full of uncertainties and as expecting<br class="d-none d-md-block d-lg-none d-xl-block">
                            parents you always worry about the well being of your baby.<br class="d-none d-md-block d-lg-none d-xl-block">
                            BabyBeat brings to you the much needed peace of mind.
                        </p>
                        <div class="btn-grp d-flex flex-column align-items-center flex-lg-row">
                            <button class="mr-lg-3 mb-3 mb-lg-0" (click)="scrollTo('bb-video')"
                                color="babybeat" button-filled>How It Works</button>   
                            <!--<a href="https://forms.gle/QaAGwLQShknG96FV9" target="_blank"
                                color="babybeat" button-outlined>Free Demo Available</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>