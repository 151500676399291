import { Component, OnInit } from '@angular/core';
import Glide from '@glidejs/glide'
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm'

@Component({
  selector: 'app-our-clients',
  templateUrl: './our-clients.component.html',
  styleUrls: ['./our-clients.component.scss']
})
export class OurClientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    new Glide('.glider__clients__in',{
      type: 'carousel',
      startAt: 0,
      perView: 6,
      bound: true,
      autoplay: 2000,
      hoverpause: false,
      animationTimingFunc: "ease",
      animationDuration: 800,
      breakpoints: {
        1200: {
          perView: 5
        },
        992: {
          perView: 4
        },
        768: {
          perView: 3
        },
        576: {
          perView: 2
        },
      }
    }).mount({ Controls, Breakpoints })
  }

}
