<!-- With reference-->
<section id="offering-section" class="bg-color-secondary-lighter">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h1 class="color-secondary">Our Maternal Digital Solutions</h1>
            </div>
            <div class="col-6 text-right">
                <a class="btn mb-3 button-navigate mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                <a class="btn button-navigate mb-3" href="#carouselExampleIndicators2" role="button" data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a>
            </div>
            <div class="col-12 pt-1 pb-2">
                <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <div class="product-container">
                                        <a routerLink="/offering/fetomax">
                                            <div class="card">
                                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetosense.jpg">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">FetoMax</h4>
                                                    <h5 class="card-text color-secondary">AI Powered Digital CTG Machine</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Portable & wireless machine.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Remote monitoring & Unlimited test storage and WhatsApp sharing.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer  text-center">
                                                    <a routerLink="/offering/fetomax" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="product-container">
                                        <a routerLink="/offering/feto-twin">
                                            <div class="card">
                                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetotwin.png">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">FetoTwin</h4>
                                                    <h5 class="card-text color-secondary">Wireless Twin Monitoring CTG Machine</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Dual Heart Monitoring.
                                                        </span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Single-Point Charging & Wireless compact kit.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/feto-twin" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="product-container">
                                        <a routerLink="/offering/feto-mini">
                                            <div class="card">
                                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetomini.png">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">FetoMini</h4>
                                                    <h5 class="card-text color-secondary">Smartphone based CTG machine</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Portable & wireless machine.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Unlimited test storage and WhatsApp sharing.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/feto-mini" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <div class="product-container">
                                        <a routerLink="/offering/babybeat">
                                            <div class="card">
                                                <img class="card-img-top" alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../assets/images/home/products/babybeat.png">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">BabyBeat</h4>
                                                    <h5 class="card-text color-secondary">Experience your baby's every beat.</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush h-100">
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Wireless fetal heart monitor.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Medical grade DIY home monitoring.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Real time report sharing.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/babybeat" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="product-container">
                                        <a routerLink="/offering/anandimaa">
                                            <div class="card">
                                                <img class="card-img-top" alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../assets/images/home/products/anandimaa.jpg">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">AnandiMaa</h4>
                                                    <h5 class="card-text color-secondary">Pregnancy care for rural mothers</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Empower healthcare providers.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Public health partnership model.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Provide pregnancy care to remotest area.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/anandimaa" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="product-container">
                                        <a routerLink="/offering/careHEr">
                                            <div class="card">
                                                <img class="card-img-top hover-overlay shadow-1-strong" alt="Health Entrepreneurs enabling healthcare providers
                                                to offer quality care to women at last mile, CareMother" src="../../../../assets/images/home/products/careher.jpg">
                                                <div class="card-header">
                                                    <h4 class="card-title color-secondary">careHER</h4>
                                                    <h5 class="card-text color-secondary">Quality care to women at last mile</h5>
                                                </div>
                                                <div class="card-body">
                                                <ul class="list-group list-group-flush" >
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Ecosystem for women at last mile.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Delivering diagnostic device.</span>
                                                    </li>
                                                    <li class="list-group-item color-primary">
                                                        <i class="fa fa-check color-secondary mr-2"></i><span>Building entrepreneurs at grassroot level.</span>
                                                    </li>
                                                </ul>
                                                </div>
                                                <div class="card-footer text-center">
                                                    <a routerLink="/offering/careHEr" class="btn bg-color-secondary text-white">Know More</a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- With SwiperJS-->
<!-- <section id="offering-section" class="bg-color-secondary-lighter">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h3 class="mb-3">Carousel cards title</h3>
            </div>
            <div class="col-6 text-right">
                <button class="btn btn-primary mb-3 mr-1 swiper-button-prev">
                    <i class="fa fa-arrow-left"></i>
                </button>
                <button class="btn btn-primary mb-3 swiper-button-next">
                    <i class="fa fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-12">
                <swiper-container class="mySwiper" loop="true" navigation="false" pagination="false" space-between="30" slides-per-view="3">
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=7c625ea379640da3ef2e24f20df7ce8d">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=42b2d9ae6feb9c4ff98b9133addfb698">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=3d2e8a2039c06dd26db977fe6ac6186a">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=7c625ea379640da3ef2e24f20df7ce8d">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=42b2d9ae6feb9c4ff98b9133addfb698">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=3d2e8a2039c06dd26db977fe6ac6186a">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=7c625ea379640da3ef2e24f20df7ce8d">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=42b2d9ae6feb9c4ff98b9133addfb698">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=3d2e8a2039c06dd26db977fe6ac6186a">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
        </div>
    </div>
</section> -->

<!-- Changed design-->
<!-- <section id="offering-section" class="bg-color-secondary-lighter">
    <div class="container inner-section">
        <div class="row mb-5" >
            <div class="col-12 text-center header">
                <h1 class="color-secondary">Our Maternal Digital Solutions</h1>
            </div>
        </div>
        <div class="row justify-content-center">
                        <div class="product-container col-md-6 col-lg-4 mb-4 mb-lg-0">
                            <a routerLink="/offering/fetomax">
                                <div class="card">
                                    <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetosense.jpg">
                                    <div class="card-header">
                                        <h4 class="card-title color-secondary">FetoMax</h4>
                                        <h5 class="card-text color-secondary">AI Powered Digital CTG Machine</h5>
                                    </div>
                                    <div class="card-body">
                                      <ul class="list-group list-group-flush" >
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Portable & wireless machine.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Remote monitoring & Unlimited test storage and WhatsApp sharing.</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="card-footer  text-center">
                                        <a routerLink="/offering/fetomax" class="btn bg-color-secondary text-white">Know More</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="product-container col-md-6 col-lg-4 mb-4 mb-lg-0">
                            <a routerLink="/offering/feto-mini">
                                <div class="card">
                                    <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetotwin.png">
                                    <div class="card-header">
                                        <h4 class="card-title color-secondary">FetoTwin</h4>
                                        <h5 class="card-text color-secondary">Wireless Twin Monitoring CTG Machine</h5>
                                    </div>
                                    <div class="card-body">
                                      <ul class="list-group list-group-flush" >
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Dual Heart Monitoring.
                                            </span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Single-Point Charging & Wireless compact kit.</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="card-footer text-center">
                                        <a routerLink="/offering/feto-mini" class="btn bg-color-secondary text-white">Know More</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="product-container col-md-6 col-lg-4 mb-4 mb-lg-0">
                            <a routerLink="/offering/feto-mini">
                                <div class="card">
                                    <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/products/fetomini.jpg">
                                    <div class="card-header">
                                        <h4 class="card-title color-secondary">FetoMini</h4>
                                        <h5 class="card-text color-secondary">Smartphone based CTG machine</h5>
                                    </div>
                                    <div class="card-body">
                                      <ul class="list-group list-group-flush" >
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Portable & wireless machine.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>AI powered auto interpretation.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Unlimited test storage and WhatsApp sharing.</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="card-footer text-center">
                                        <a routerLink="/offering/feto-mini" class="btn bg-color-secondary text-white">Know More</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="product-container col-md-6 col-lg-4 mb-4 mt-lg-4">
                            <a routerLink="/offering/babybeat">
                                <div class="card">
                                    <img class="card-img-top" alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../assets/images/home/products/babybeat.jpg">
                                    <div class="card-header">
                                        <h4 class="card-title color-secondary">BabyBeat</h4>
                                        <h5 class="card-text color-secondary">Experience your baby's every beat.</h5>
                                    </div>
                                    <div class="card-body">
                                      <ul class="list-group list-group-flush h-100">
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Wireless fetal heart monitor.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Medical grade DIY home monitoring.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Real time report sharing.</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="card-footer text-center">
                                        <a routerLink="/offering/babybeat" class="btn bg-color-secondary text-white">Know More</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="product-container col-md-6 col-lg-4 mb-4 mt-lg-4">
                            <a routerLink="/offering/anandimaa">
                                <div class="card">
                                    <img class="card-img-top" alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" src="../../../../assets/images/home/products/anandimaa.jpg">
                                    <div class="card-header">
                                        <h4 class="card-title color-secondary">AnandiMaa</h4>
                                        <h5 class="card-text color-secondary">Pregnancy care for rural mothers</h5>
                                    </div>
                                    <div class="card-body">
                                      <ul class="list-group list-group-flush" >
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Empower healthcare providers.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Public health partnership model.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Provide pregnancy care to remotest area.</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="card-footer text-center">
                                        <a routerLink="/offering/anandimaa" class="btn bg-color-secondary text-white">Know More</a>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="product-container col-md-6 col-lg-4 mb-4 mt-lg-4">
                            <a routerLink="/offering/careHEr">
                                <div class="card">
                                    <img class="card-img-top hover-overlay shadow-1-strong" alt="Health Entrepreneurs enabling healthcare providers
                                    to offer quality care to women at last mile, CareMother" src="../../../../assets/images/home/products/careher.jpg">
                                    <div class="card-header">
                                        <h4 class="card-title color-secondary">careHER</h4>
                                        <h5 class="card-text color-secondary">Quality care to women at last mile</h5>
                                    </div>
                                    <div class="card-body">
                                      <ul class="list-group list-group-flush" >
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Ecosystem for women at last mile.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Delivering diagnostic device.</span>
                                        </li>
                                        <li class="list-group-item color-primary">
                                            <i class="fa fa-check color-secondary mr-2"></i><span>Building entrepreneurs at grassroot level.</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div class="card-footer text-center">
                                        <a routerLink="/offering/careHEr" class="btn bg-color-secondary text-white">Know More</a>
                                    </div>
                                </div>
                            </a>
                        </div>
        </div>
    </div>
    <div class="bg-color-primary-light">
        <div class="container inner-section">
            <div class="row explore-container" >
                <div class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-lg-between">
                    <h1 class="text-white text-center text-lg-left font-28 mb-4 mb-lg-0">
                        Our pregnancy care solutions are tailor made for every <br>
                        hospital and doctor’s requirement.
                    </h1>
                    <a routerLink="/offering" color="white" button-filled>
                        View All Offerings</a>
                </div>
            </div>
        </div>
    </div>
</section> -->
