import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BabybeatComponent } from './babybeat.component';


const routes: Routes = [
  {
    path: "",
    component: BabybeatComponent,
    data: {
      title: 'BabyBeat Fetal Monitor in India',
      keywords: 'BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home',
      description: "Monitor your baby's heartbeat anytime, anywhere with CareMother BabyBeat now 9930990900"
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BabybeatLandingRoutingModule { }
