<section id="testimonials-section">
    
    <div class="container-fluid container-testimonials inner-section">
        <div class="row" >
            <div class="col-12">
                <div>
                    <h1 class="color-secondary text-center">Here’s what <br>
                        people are saying about CareMother</h1>
                </div>
            </div>
        </div>
        <div class="glide-container" >
            <div class="glide glide__testimonials_testimo">
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides">
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/employee-testimonials/munmun-testimonial.jpeg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                In all of my experiences working in various organizations, 
                                                CareMother has been the best workplace in terms of gender equality, 
                                                opportunities, and growth. Couldn't have asked for a better workplace environment. 
                                                Team CareMother rocks!
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Munmun Ganguly</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slider 6-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/pritee.jpg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                Proud to serve with 'superheroes' on field by using smartphone 
                                                based CareMother technology which is simplifying a process to 
                                                provide affordable healthcare delivery for safe pregnancy.
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Pritee Dehukar</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slide 3-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/Harshad.jpg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                I am feeling really fortunate to be a part of the CareMother. 
                                                Embraced by a team full of passionate, dedicated, strong, loving, 
                                                and caring people. We are on this divine mission to provide healthy 
                                                pregnancy experiences.
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Harshad Narkhede</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slide 4-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/ramesh.jpg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                It's been a great opportunity to join Caremother. 
                                                As a Mobile App Developer, I got an opportunity to work on some 
                                                of the challenging problems in the pregnancy health domain. 
                                                I am glad to work in such a challenging and positive work environment.
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Ramesh Mhalgi</p>
                                            <p class="font-15 color-light">Navi Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slider 5-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/kiran.jpg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                I enjoy working with CareMother. 
                                                I can proudly say this is my first and best move. 
                                                Work environment is good. 
                                                CareMother is fundamentally a strong company with 
                                                lot of opportunities to learn.
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Kiran Kumawat</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slider 6-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/employee-testimonials/dipneeta-testimonial.jpeg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                It has been an amazing experience working with CareMother. 
                                                I feel comfortable working in a stress-free environment. 
                                                CareMother offers a great opportunity for professional and personal growth. 
                                                It provides a platform to learn and explore new places, technology and customers. 
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Dipneeta Barad</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slider 7-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/team/images/krishna.jpg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                CareMother is a great place to work. 
                                                The culture is transparent, every employee, 
                                                irrespective of their position is given a chance to be heard, 
                                                and there are ample opportunities available to those who want to build a career here.
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Krishna Burra</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!--Slider 8-->
                        <li class="glide__slide">
                            <div class="row slide-inner">
                                <div class="slider-img d-flex">
                                    <div class="inner-img">
                                        <img alt="NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" src="../../../../../assets/images/company/life/employee-testimonials/anjali-testimonial.jpeg">
                                    </div>
                                    <div class="inner-testimonials d-flex flex-column justify-content-center bg-color-secondary-lighter">
                                        <div class="mb-4 mb-lg-5">
                                            <p class="font-15 color-light">
                                                Great work ambiance and team spirit. 
                                                As part of a team that is doing its best to develop new 
                                                technology, I appreciate the mindset and the culture.
                                            </p>
                                        </div>
                                        <div>
                                            <p class="color-primary">Anjali Chaurasia</p>
                                            <p class="font-15 color-light">Mumbai</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="glide__bullets" data-glide-el="controls[nav]">
                    <button class="glide__bullet" data-glide-dir="=0"></button>
                    <button class="glide__bullet" data-glide-dir="=1"></button>
                    <button class="glide__bullet" data-glide-dir="=2"></button>
                    <button class="glide__bullet" data-glide-dir="=3"></button>
                    <button class="glide__bullet" data-glide-dir="=4"></button>
                    <button class="glide__bullet" data-glide-dir="=5"></button>
                    <button class="glide__bullet" data-glide-dir="=6"></button>
                    <button class="glide__bullet" data-glide-dir="=7"></button>
                </div>
            </div>                    
        </div>
    </div>
</section>