<section id="how-it-works-section">
    <div class="container  inner-section">
        <div class="row mb-5" *ngIf="isVideoTitle">
            <div class="col-12 text-center">
                <h2 class="introHeader">HOW FetoMax WORKS?</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="video-container">
                    <iframe  class="youtube" [src]='safeURL' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>