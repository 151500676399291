import { Component, HostListener, OnInit } from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
import { HomeService } from './home.service';
import { LandingDataService } from '../services/landing-data.service';
interface Utility{
  anandiMaaDocAndHW:number,
    anandiMaaInCountries:number,
    anandiMaaRollingCount:{
      mothersReached:number,
      partnersJoined:number,
      statesCovered:number,
      villageCovered:number,
      visitsMade:number
    },
    fetomaxTotalMothers:string,
    fetomaxTotalStates:number,
    homeRollingCount:{
      totalCountries:number,
      totalMothers:number,
      totalOrgs:number,
      totalStates:number
    },
    totalDoctors:number
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  utilityObj={
    anandiMaaDocAndHW:1200,
    anandiMaaInCountries:4,
    anandiMaaRollingCount:{
      mothersReached:130000,
      partnersJoined:16,
      statesCovered:15,
      villageCovered:1400,
      visitsMade:300000
    },
    fetomaxTotalMothers:'2L',
    fetomaxTotalStates:20,
    homeRollingCount:{
      totalCountries:5,
      totalMothers:500000,
      totalOrgs:750,
      totalStates:25
    },
    totalDoctors:2000
  }
  constructor(
    private title: Title, private landingService:LandingDataService,
    private meta: Meta, private homeService:HomeService) {
      
    this.title.setTitle('CareMother FetoMax NST/CTG Machine (Fetal Monitor) in India ');
    this.meta.updateTag({ name: 'description', content: "CareMother FetoMax is a Smartphone based digital, wireless, portable NST/CTG machine, Fetal Monitor, Fetal Doppler Manufacturer in Pune, Mumbai, India. 9930990900" })
    this.meta.updateTag({ name: 'keywords', content: "NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother" })
    }

  ngOnInit() {
    this.getUtility();
    window.scrollTo(0, 0);}
  
    isFixed: boolean;
    @HostListener("window:scroll", [])
    onWindowScroll() {
      if(typeof window !== undefined ){
            
        const offset =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        if (offset > 400) {
          this.isFixed = true;
        } else if(offset <= 500){
          this.isFixed = false
        }
        else {
          this.isFixed = false;
        }
      }
  }

  getUtility(){
    let openingsData = this.homeService.getUtility().subscribe(data => {
      if(data){    
        let obj={
          anandiMaaDocAndHW:data['anandiMaaDocAndHW']?data['anandiMaaDocAndHW']:0,
          anandiMaaInCountries:data['anandiMaaDocAndHW']?data['anandiMaaInCountries']:0,
          anandiMaaRollingCount:{
            mothersReached:data['anandiMaaRollingCount']['mothersReached']?data['anandiMaaRollingCount']['mothersReached']:0,
            partnersJoined:data['anandiMaaRollingCount']['partnersJoined']?data['anandiMaaRollingCount']['partnersJoined']:0,
            statesCovered:data['anandiMaaRollingCount']['statesCovered']?data['anandiMaaRollingCount']['statesCovered']:0,
            villageCovered:data['anandiMaaRollingCount']['villageCovered']?data['anandiMaaRollingCount']['villageCovered']:0,
            visitsMade:data['anandiMaaRollingCount']['visitsMade']?data['anandiMaaRollingCount']['visitsMade']:0
          },
          fetomaxTotalMothers:data['fetomaxTotalMothers']?data['fetomaxTotalMothers']:0,
          fetomaxTotalStates:data['fetomaxTotalStates']?data['fetomaxTotalStates']:0,
          homeRollingCount:{
            totalCountries:data['homeRollingCount']['totalCountries']?data['homeRollingCount']['totalCountries']:0,
            totalMothers:data['homeRollingCount']['totalMothers']?data['homeRollingCount']['totalMothers']:0,
            totalOrgs:data['homeRollingCount']['totalOrgs']?data['homeRollingCount']['totalOrgs']:0,
            totalStates:data['homeRollingCount']['totalStates']?data['homeRollingCount']['totalStates']:0
          },
          totalDoctors:data['totalDoctors']?data['totalDoctors']:0
        }

        obj=JSON.parse(JSON.stringify(obj));
        //Set Data
        this.landingService.setPlatformNumbers(obj);
      }else{
        this.landingService.setPlatformNumbers(this.utilityObj);
      }
      openingsData.unsubscribe();
    })
  }

}
