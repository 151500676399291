<div class="fixed-socials inner-section" [class.isVisible]="isVisible">
    <div class="socials-inner">
        <a href=" https://www.facebook.com/caremotherindia/" target="_blank">
            <span class="fa fa-facebook-square" aria-hidden="true"></span>
        </a>
        <a href=" https://www.instagram.com/caremotherbydotohealth/" target="_blank">
            <span class="fa fa-instagram" aria-hidden="true"></span>
        </a>
        <a href="https://www.linkedin.com/company/caremother/" target="_blank">
            <span class="fa fa-linkedin-square" aria-hidden="true"></span>
        </a>
    </div>
</div>

<a class="whatsapp-btn" color="babybeat" button-filled target="_blank" href="https://wa.me//919930990900">
    <i class="fa fa-whatsapp" aria-hidden="true"></i><span>Chat Now</span>
</a>