<section>
    <div class="container-fluid inner-section">
        <div class="row" >
            <div class="col-12 mt-2 d-flex flex-column flex-lg-row align-items-center">
                <div class="details-container">
                    <h1 class="color-primary font-36">
                        Simplifying Pregnancy And <br class="d-none d-xl-block">
                        New Born Monitoring<br class="d-none d-xl-block">
                        <!-- Tools For Healthcare Providers -->
                    </h1>
                    <p class="color-primary my-3">
                        We at CareMother, enable healthcare providers to deliver<br class="d-none d-lg-block">
                        top-quality pregnancy care at the hospital and remotely<br class="d-none d-lg-block">
                        with digital and AI powered tools.<br class="d-none d-lg-block">
                    </p>
                    <!--<div>
                        <a href="https://caremother.app.link/Y1pte3dAe6" class="btn-filled-secondary" >Download App</a>            
                    </div>-->
                    <div>
                        <a color="secondary" button-filled
                        href="https://forms.gle/QaAGwLQShknG96FV9"target="_blank">
                            Get Free Demo</a>           
                    </div>
                </div>
                <div class="glide-container">
                    <div class="glide glide__slider">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-6.png">
                                        </div>
                                    </div>
                                </li>
                                <!--Slider 2-->
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-1.png">
                                        </div>
                                    </div>
                                </li>
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-11 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-2.png">
                                        </div>
                                    </div>
                                </li>
                                <!--Slide 3-->
                                <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-3.png">
                                        </div>
                                    </div>
                                </li>
                                <!--Slide 4-->
                                <!-- <li class="glide__slide">
                                    <div class="row slide-inner align-items-center">
                                        <div class="col-12 img-container">
                                            <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" 
                                            src="../../../../assets/images/home/intro-slider/slider-img-4.png">
                                        </div>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                        <!--
                        <div class="glide__arrows" data-glide-el="controls">
                            <button class="glide__arrow glide__arrow--left" data-glide-dir="<" >
                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                            </button>
                            <button class="glide__arrow glide__arrow--right" data-glide-dir=">" >
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </button>
                        </div>-->
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                            <button class="glide__bullet" data-glide-dir="=3"></button>
                            <!-- <button class="glide__bullet" data-glide-dir="=3"></button> -->
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>