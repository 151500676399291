<section class="d-flex align-items-center">
    <div class="container-fluid inner-section">
        <div class="row align-items-center justify-content-center justify-content-lg-unset">
            <div class="col-12 col-sm-8 col-md-7 col-lg-5">
                <div class="details-container mb-5 mb-lg-0" >
                    <img alt="CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers" class="mb-4" src="../../../../../assets/images/products/anandimaa/intro/cm-white-logo.svg">
                    <h1 class="text-white font-45 mb-2">AnandiMaa</h1>
                    <h2 class="text-white mb-4">
                        Enabling remote pregnancy care with <br>
                        governments and NGO’s
                    </h2>
                    <div>
                        <a target="_blank" href="https://forms.gle/QaAGwLQShknG96FV9" color="secondary" button-filled>Get Free Demo</a>            
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8 col-md-7 col-lg-7">
                <div class="image-container mt-5 mt-lg-0 d-flex align-items-center justify-content-end">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../../assets/images/products/anandimaa/intro/anandimaa kit bg.png">
                </div>
            </div>
        </div>
    </div>
</section>