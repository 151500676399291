<section>
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex align-items-center justify-content-center flex-column flex-md-row">
                    <img alt="CareMother Benefits and Perks" src="../../../../../assets/images/company/careers/benefits/career-benefits.png">
                    <div class="details-container">
                        <h1 class="color-secondary mb-4" >Benefits and Perks</h1>
                        <p class="mb-3 font-16" ><i class="fa fa-check mr-3 color-secondary"></i>Flexible forms of cooperation</p>
                        <p class="mb-3 font-16" ><i class="fa fa-check mr-3 color-secondary"></i>Necessary reimbursement</p>
                        <p class="mb-3 font-16" ><i class="fa fa-check mr-3 color-secondary"></i>Challenging work statements to work upon</p>
                        <p class="mb-3 font-16" ><i class="fa fa-check mr-3 color-secondary"></i>Paid sick leaves & casual leaves</p>
                        <p class="mb-3 font-16" ><i class="fa fa-check mr-3 color-secondary"></i>Medical insurance plans</p>
                        <p class="mb-4 font-16" ><i class="fa fa-check mr-3 color-secondary"></i>Free snacks & beverages</p>
                        <div class="btn-grp" >
                            <a routerLink="/company/life-at-caremother" color="secondary" button-filled>Life At Caremother</a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>