<section>
    <div class="container inner-section" >
        <div class="row">
            <div class="col-12">
                <h1 class="text-center color-secondary">Locate Us</h1>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.42623070325!2d73.7956293648138!3d18.55481308738907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf26db9b8d11%3A0x784335a7ef5b9860!2sDeron%20Heights!5e0!3m2!1sen!2sin!4v1676557116416!5m2!1sen!2sin"
                style="border:0;" allowfullscreen=""></iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="text-center">701, Deron Heights,Varsha Park Apartment,<br class="d-none d-md-block">
                    Above Ranka jewellers, Opposite Mahabaleshwar Restaurant,
                    Baner,Pune,<br class="d-none d-md-block">
                    Maharashtra, India</p>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-12">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.428824481309!2d72.91555777466654!3d19.13269745019125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c7bd70b0f40d%3A0x5934be797b58e5c1!2sRahul%20Bajaj%20Technology%20Innovation%20Centre!5e0!3m2!1sen!2sin!4v1712053954259!5m2!1sen!2sin"  style="border:0;" allowfullscreen=""></iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p class="text-center">Office.No- 6034, SINE,<br class="d-none d-md-block">
                    Rahul Bajaj Technology Innovation Centre,
                    IIT, Powai, Mumbai,<br class="d-none d-md-block">
                    Maharashtra, India</p>
            </div>
        </div>
    </div>
</section>