<section id="certificates-section" >
    <div class="container inner-section">
        <!-- <div class="row mb-5" >
            <div class="col-12 text-center header">
                <h1 class="color-secondary">Our Maternal Digital Solutions</h1>
            </div>
        </div> -->
        <div class="row justify-content-center">
                        <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div class="card">
                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/certifications/certification-ISO.svg">
                                <div class="card-footer text-center bg-color-secondary-lighter">
                                    <h5 class="card-text color-primary">ISO 13485:2016</h5>
                                </div>
                            </div>
                        </div>
                        <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div class="card">
                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/certifications/certification-CDSCO.svg">
                                <div class="card-footer text-center bg-color-secondary-lighter">
                                    <h5 class="card-text color-primary">CDSCO MFG/MD/2024/000344
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div class="card">
                                <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/certifications/certification-CE.svg">
                                <div class="card-footer text-center bg-color-secondary-lighter">
                                    <h5 class="card-text color-primary">European Conformity
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="product-container col-md-6 col-lg-3 mb-4 mb-lg-0">
                            <div class="card">
                                <img class="card-img-top w-40 mx-auto" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetomax, CareMother" src="../../../../assets/images/home/certifications/iec.jpg">
                                <div class="card-footer text-center bg-color-secondary-lighter">
                                    <h5 class="card-text color-primary">TUV INDIA <br/> EMC Testing IEC 60601-1-2:2014
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</section>