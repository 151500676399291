import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss']
})
export class YoutubeVideoComponent implements OnInit {
  safeURL:any;
  heading:any;
  videoTitle:string = '';
  isVideoTitle:boolean;
  constructor(private router: Router,private _sanitizer: DomSanitizer) {
    if(this.router.url.includes('/offering/homecare')){
      this.heading="HomeCare";
      this.videoTitle = '';
      this.isVideoTitle = false;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/k8NYUuUVb9U');
    }
    else if(this.router.url.includes('/offering/fetosense')){
      this.heading="FetoMax";
      this.videoTitle = 'HOW FetoMax WORKS?';
      this.isVideoTitle = true;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/BPis8PALD_o');
    }
    else if(this.router.url === '/offering/anandimaa'){
      this.heading="Anandimaa";
      this.videoTitle = '';
      this.isVideoTitle = false;
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/yqT7l9l3OMY');
    }
  }

  ngOnInit() {
  }

}
