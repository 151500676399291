<div [class.isFixed]="isFixed">
    <!--<app-social-header></app-social-header>-->
    <app-main-navbar></app-main-navbar>
    <!--<app-fixed-socials></app-fixed-socials>-->
    <!--<app-download-app></app-download-app>-->
    <app-image-slider></app-image-slider>
    <app-rolling-counter></app-rolling-counter>
    <app-home-info></app-home-info>
    <!--<app-featured></app-featured>-->
    <!-- <app-offering></app-offering> -->
    <app-home-offering></app-home-offering>
    <app-home-certificates></app-home-certificates>
    <app-home-partners></app-home-partners>
    <app-featured-in></app-featured-in>
    <app-our-clients></app-our-clients>
    <!--<app-standards></app-standards>-->
    <app-testimonials></app-testimonials>
    <app-home-awards></app-home-awards>
             
    <!--<app-infographics></app-infographics>-->
    <app-home-videos></app-home-videos>
    <!--<app-home-updates></app-home-updates>
    <app-partners></app-partners>-->
    <!--<app-home-element></app-home-element>-->
    <app-footer></app-footer>
</div>