<div>
    <app-main-navbar></app-main-navbar>
    <!--<app-fixed-socials></app-fixed-socials>-->
    <app-life-intro></app-life-intro>
    <app-page-nav></app-page-nav>
    <app-life-values></app-life-values>
    <app-life-team></app-life-team>
    <!-- <app-employee-testimonials></app-employee-testimonials> -->
    <!-- <app-life-work></app-life-work> -->
    <app-footer></app-footer>
</div>