<section>
    <div class="container inner-section">
        <div class="row">
            <div class="col">
                <div class="inner-block">
                    <h1 class="color-secondary text-center">
                        Here’s How FetoMax Works
                    </h1>
                    <div class="video d-flex flex-column align-items-center">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/products/fetosense/videos/feto-08.png">
                        <iframe  class="youtube" [src]='safeURL' frameborder="0"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"  allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>