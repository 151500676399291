import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { ProductsComponent } from "./products.component";

const routes: Routes = [

  {
    path: "",
    component: ProductsComponent,
    data: {
      title: 'CareMother FetoMax NST/CTG Machine (Fetal Monitor) in India',
      keywords: 'NST / CTG Machine in India, Fetal Monitor, Fetal Doppler, Portable CTG, Smart CTG, Homecare CTG, FetoMax, CareMother',
      description: 'CareMother Fetomax is a Smartphone based digital, wireless, portable NST/CTG machine, Fetal Monitor, Fetal Doppler Manufacturer in Pune, Mumbai, India. 9930990900'
    }
  },
  {
    path: "babybeat",
    loadChildren: () => import('./babybeat/babybeat.module').then(m => m.BabybeatModule)
  },
  {
    path: "fetomax",
    loadChildren: () => import('./fetosense/fetosense.module').then(m => m.FetosenseModule)
  },
  {
    path: "feto-twin",
    loadChildren: () => import('./fetomax-twin/fetosense.module').then(m => m.FetosenseModule)
  },
  {
    path: "feto-mini",
    loadChildren: () => import('./fetomax-mini/fetomax-mini.module').then(m => m.FetoMaxMiniModule)
  },
  {
    path: "anandimaa",
    loadChildren: () => import('./anandimaa/anandimaa.module').then(m => m.AnandimaaModule)
  },
  {
    path: "parent",
    loadChildren: () => import('./parent/parent.module').then(m => m.ParentModule)
  },
  {
    path: "healthcare",
    loadChildren: () => import('./healthcare/healthcare.module').then(m => m.HealthcareModule)
  },
  {
    path: "homecare",
    loadChildren: () => import('./homecare/homecare.module').then(m => m.HomecareModule)
  },
  {
    path: "covid",
    loadChildren: () => import('./covid/covid.module').then(m => m.CovidModule)
  },
  {
    path: "careHEr",
    loadChildren: () => import('./careHEr/careHEr.module').then(m => m.CareHErModule)
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
