export const environment = {
  production: false,
  firebaseConfOld: {
    apiKey: "AIzaSyC8kC_4IscJewvVEW91pYfom3py492hIs0",
    authDomain: "careher-v1.firebaseapp.com",
    projectId: "careher-v1",
    storageBucket: "careher-v1.appspot.com",
    messagingSenderId: "17202283901",
    appId: "1:17202283901:web:c2a0e4122490c3e1367814",
    measurementId: "G-DHW4B4MTXY",
  },
  firebaseConf:{
    apiKey: "AIzaSyB8S_h2XHxEr_oVH95PWiC8CQm51AdZRFw",
    authDomain: "fetosense-v2-2.firebaseapp.com",
    projectId: "fetosense-v2-2",
    storageBucket: "fetosense-v2-2.appspot.com",
    messagingSenderId: "527776070670",
    appId: "1:527776070670:web:24bcc21a7289021c49505f",
    measurementId: "G-ZJRRVPEJ5P"
  }
}; 
 