<section id="awards-section" class="bg-color-secondary-lighter">
  <div class="container inner-section">
    <div class="row heading-container" >
        <div class="col-12 text-center">
            <h1 class="color-secondary">Our innovation has won many global awards<br class="d-none d-md-block">
              and received recognitions</h1>
        </div>
    </div>
    <div class="row gallery-container">
      <!-- Column 1 -->
      <div class="col-md-6 col-lg-3 image-column column-1">
        <div class="inner-img img-1" >
          <img alt="CareMother - Global Digital Health Summit, Expo & Awards 2023" src="../../../../../assets/images/shared/awards/Global Digital Health Summit.jpg">
          <div class="img-description">
            <p class="text-center text-white">Global Digital Health Summit, Expo & Awards 2023</p>
          </div>
        </div>
        
        <div class="inner-img img-2" >
          <img alt="CareMother - Google SMB Heroes, Singapore" src="../../../../../assets/images/shared/awards/Awards-5.jpg">
          <div class="img-description">
            <p class="text-center text-white">Google SMB Heroes, Singapore</p>
          </div>
        </div>
        
        <div class="proud-moments" >
          <img alt="CareMother - Winner of Accenture Innovation Jockey" src="../../../../../assets/images/company/about/proud-moments/accenture.svg">
          <p class="font-15 text-center">Winner of Accenture Innovation Jockey</p>
        </div>
        
        <div class="inner-img img-3" >
          <img alt="CareMother - Impact1 at Stanford Biodesign Award" src="../../../../../assets/images/shared/awards/Impact1 at Stanford Biodesign Award.jpg">
          <div class="img-description">
            <p class="text-center text-white">Impact1 at Stanford Biodesign Award</p>
          </div>
        </div>
      </div>
      <!-- Column 2 -->
      <div class="col-md-6 col-lg-3 image-column column-2">
        <div class="proud-moments" >
          <img alt="CareMother - Winner of ZS PRIZE: Advancing Healthcare Innovation" src="../../../../../assets/images/company/about/proud-moments/ZS-Prize-Footer-logo-RGB.svg">
          <p class="font-15 text-center">Winner of ZS PRIZE:<br>Advancing Healthcare Innovation</p>
        </div>

        <div class="proud-moments" >
          <img alt="CareMother - Winner of Infosys InfyMakers Award" src="../../../../../assets/images/company/about/proud-moments/Infosys-logo-660x350_large.svg">
          <p class="font-15 text-center">Winner of Infosys InfyMakers Award</p>
        </div>
        
        <!-- <div class="inner-img img-1" >
          <img alt="CareMother - The president Innovation Scholar Award" src="../../../../../assets/images/shared/awards/The president innovation scholar award.jpeg">
          <div class="img-description">
            <p class="text-center text-white">The president Innovation Scholar Award</p>
          </div>
        </div> -->
        
        <div class="inner-img img-2" >
          <img alt="CareMother - 3M CII Tech-Innovation, Bangalore" src="../../../../../assets/images/shared/awards/award-10.jpg">
          <div class="img-description">
            <p class="text-center text-white">3M CII Tech-Innovation, Bangalore</p>
          </div>
        </div>
      </div>
      <!-- Column 3 -->
      <div class="col-md-6 col-lg-3 image-column column-3">
        <div class="inner-img img-1" >
          <img alt="CareMother - Magnetic Maharashtra, Mumbai" src="../../../../../assets/images/shared/awards/Awards-04.jpg">
          <div class="img-description">
            <p class="text-center text-white">Magnetic Maharashtra, Mumbai</p>
          </div>
        </div>
        <div class="inner-img img-3" >
          <img alt="CareMother - The president Innovation Scholar Award" src="../../../../../assets/images/shared/awards/The president innovation scholar award.jpeg">
          <div class="img-description">
            <p class="text-center text-white">The president Innovation Scholar Award</p>
          </div>
        </div>
        <div class="proud-moments" >
          <img alt="CareMother - Winner of Global Problem Solver Challenge at CISCO" src="../../../../../assets/images/company/about/proud-moments/1200px-Cisco_logo_blue_2016.svg.svg">
          <p class="font-15 text-center">Winner of Global Problem Solver Challenge at CISCO</p>
        </div>
        <div class="inner-img img-3" >
          <img alt="CareMother - Nitiayog, Ex-CEO, G20 Sherpa Amitabh Kant" src="../../../../../assets/images/shared/awards/Nitiayog.jpg">
          <div class="img-description">
            <p class="text-center text-white">Nitiayog, Ex-CEO, G20 Sherpa Amitabh Kant</p>
          </div>
        </div>
      </div>
      <!-- Column 4 -->
      <div class="col-md-6 col-lg-3 image-column column-4">
        <div class="inner-img img-1" >
          <img alt="CareMother - The President's Innovation Scholar interaction with PM Modi, Delhi" src="../../../../../assets/images/shared/awards/Awards-2.jpg">
          <div class="img-description">
            <p class="text-center text-white">The President's Innovation Scholar interaction with PM Modi, Delhi</p>
          </div>
        </div>
        
        <div class="inner-img img-2" >
          <img alt="CareMother - IRBI, Moscow" src="../../../../../assets/images/shared/awards/awards-7.jpg">
          <div class="img-description">
            <p class="text-center text-white">IRBI, Moscow</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>