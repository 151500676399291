import { Component, OnInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';

@Component({
  selector: 'app-home-info',
  templateUrl: './home-info.component.html',
  styleUrls: ['./home-info.component.scss']
})
export class HomeInfoComponent implements OnInit {
  orgs:number=750;
  docs:number=2000;
  infoStmt=`${this.orgs}+ hospitals and ${this.docs}+ doctors trust CareMother `;
  constructor(private landingService:LandingDataService) { 
    
  }

  ngOnInit() {
    this.landingService.currentPlatformNumbers.subscribe(data=>{
      if(data && data.homeRollingCount && data.totalDoctors){
        this.orgs=data.homeRollingCount.totalOrgs;
        this.docs=data.totalDoctors;
        // this.infoStmt=`${this.orgs}+ hospitals and ${this.docs}+ doctors trust CareMother `;
        this.infoStmt=`${this.docs}+ Doctors trust CareMother next generation`;
      }
    });
  }

}
