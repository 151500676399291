<div [class.isFixed]="isFixed">
    <app-main-navbar></app-main-navbar>
    <!--<app-fixed-socials></app-fixed-socials>-->
    <app-products-intro></app-products-intro>
    <!--<app-page-nav></app-page-nav>-->
    <app-products-fetosense></app-products-fetosense>
    <app-products-fetomax-twin></app-products-fetomax-twin>
    <app-products-anandimaa></app-products-anandimaa>
    <app-products-babybeat></app-products-babybeat>
    <app-products-fetomax-mini></app-products-fetomax-mini>
    <app-products-careHEr></app-products-careHEr>
    <!--<app-products-element></app-products-element>-->
    <app-footer></app-footer>
</div>