<section id="awards-section" >
    <div class="container inner-section">
        <div class="row mb-5" >
            <div class="col-6">
                <h1 class="color-secondary mb-3">Awards And Recognitions</h1>
            </div>
            <div class="col-6 text-right">
                <button class="btn button-navigate mb-3 swiper-button-next" (click)="navigateToAwards()">
                    View More
                </button>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-sm-3 mb-4">
                <div class="card o-hidden h-100" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-2.jpg">
                    <div class="card-body">
                        <p class="font-15">
                            The President's Innovation Scholar interaction with PM Modi, Delhi
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 mb-4">
                <div class="card o-hidden h-100" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-3.jpg">
                    <div class="card-body">
                        <p class="font-15">
                            Magnetic Maharashtra, Mumbai
                        </p>
                    </div>
                </div>
            </div> 
            <div class="col-sm-3 mb-4">
                <div class="card o-hidden h-100" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-7.jpg">
                    <div class="card-body">
                        <p class="font-15">
                            Impact1 at Stanford Biodesign
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 mb-4">
                <div class="card o-hidden h-100" >
                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="card-img-top" src="./assets/images/landing/products/tinified/Awards-8.jpg">
                    <div class="card-body">
                        <p class="font-15">
                            Global Digital Health Summit
                        </p>
                    </div>
                </div>
            </div>       
        </div>
    </div>
</section>

<!-- <section id="featured-section">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-6">
                <h1 class="color-secondary mb-3">Awards And Recognitions</h1>
            </div>
            <div class="col-6 text-right">
                <button class="btn button-navigate mb-3 swiper-button-next" (click)="navigateToAwards()">
                    View More
                </button>
            </div>
            <div class="col-12">
                <swiper-container class="mySwiper" loop="true" navigation="false" pagination="false" space-between="30" slides-per-view="3">
                    <swiper-slide class="card-wrapper">
                        <div class="card">
                            <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/landing/products/tinified/Awards-2.jpg">
                            <div class="card-footer text-center">
                                <h5 class="card-text color-primary">The President's Innovation Scholar interaction with PM Modi, Delhi
                                </h5>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="card-wrapper">
                        <div class="card">
                            <img class="card-img-top" alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/landing/products/tinified/Awards-3.jpg">
                            <div class="card-footer text-center">
                                <h5 class="card-text color-primary">Magnetic Maharashtra, Mumbai
                                </h5>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="card-wrapper">
                        <div class="card">
                            <img class="card-img-top" alt="100%x280" src="https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=3d2e8a2039c06dd26db977fe6ac6186a">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=7c625ea379640da3ef2e24f20df7ce8d">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=42b2d9ae6feb9c4ff98b9133addfb698">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=3d2e8a2039c06dd26db977fe6ac6186a">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=7c625ea379640da3ef2e24f20df7ce8d">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=42b2d9ae6feb9c4ff98b9133addfb698">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="card">
                            <img class="img-fluid" alt="100%x280" src="https://images.unsplash.com/photo-1532712938310-34cb3982ef74?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMDc0fQ&s=3d2e8a2039c06dd26db977fe6ac6186a">
                            <div class="card-body">
                                <h4 class="card-title">Special title treatment</h4>
                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
        </div>
    </div>
</section> -->