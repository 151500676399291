<section class="bg-color-bb-light">
    <div class="container inner-section">
        <div class="row" >
            <div class="col-12 text-center inner-container mb-5">
                <h1 class="color-bb-secondary">Discover Free Demo Locations</h1>
            </div>
            <div class="col-12 inner-container-form">
                <form action="https://forms.gle/QaAGwLQShknG96FV9" target="_blank">
                    <div class="subscribe-form form-group">
                        <p class="text-center text-md-left mb-4 mb-md-0 mr-md-4">We're available in<br class="d-block d-md-none"> Mumbai | Pune | Bangalore | Hyderabad</p>
                        <button type="submit" color="babybeat" button-filled>
                            Get Started
                        </button>
                    </div>
                </form>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="publications" class="card o-hidden h-100" >
                        <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/working-laptop.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Publications
                            </p>
                        </div>
                    </div> 
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="awards&recognitions" class="card o-hidden h-100" >
                        <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/champion-golden-trophy-winner-with-sport-player-hands-sport-stadium.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Awards & Recognitions
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="supporters&partners" class="card o-hidden h-100" >
                        <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/diverse-hands-united-business-teamwork-gesture.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Supporters & Partners
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 mb-4 mb-md-0">
                <div class="card-container">
                    <div [routerLink] = "['/company/about-us']" fragment="newsroom" class="card o-hidden h-100" >
                        <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="card-img-top" src="../../../../../assets/images/products/fetosense/locations/woman-reading-news-articles-mobile-phone.jpg">
                        <div class="card-body">
                            <p class="font-15 color-light text-center">
                                Newsroom
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>