import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { AnandimaaComponent } from "./anandimaa.component";


const routes: Routes = [

  {
    path: "",
    component: AnandimaaComponent,
    data: {
      title: 'CareMother Anandimaa | High risk pregnancy management platform',
      keywords: 'CareMother, high risk pregnancy management platform, CareMother Anandimaa, Pregnancy care for rural mothers',
      description: 'CareMother Anandimaa - an AI enabled, high risk pregnancy management platform in India. 9930990900'
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnandimaaRoutingModule { }
