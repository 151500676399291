import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-babybeat-videos',
  templateUrl: './babybeat-videos.component.html',
  styleUrls: ['./babybeat-videos.component.scss']
})
export class BabybeatVideosComponent implements OnInit {
  safeURL: any;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/7E4Gia9arcU');
  }

}
