import { Component, HostListener, OnInit, AfterContentInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';

@Component({
  selector: 'app-anandimaa-counter',
  templateUrl: './anandimaa-counter.component.html',
  styleUrls: ['./anandimaa-counter.component.scss']
})
export class AnandimaaCounterComponent implements OnInit, AfterContentInit {

  visitsCount:number = 0;
  mothersCount:number = 0;
  villagesCount:number = 0;
  statesCount:number = 0;
  partnersCount:number = 0;

  totalVisitsCount:number = 300000;
  totalMothersCount:number = 130000;
  totalVillagesCount:number = 1400;
  totalStatesCount:number = 15;
  totalPartnersCount:number = 16;

  visitsCountStop: any;
  mothersCountStop: any;
  villagesCountStop: any;
  statesCountStop: any;
  partnersCountStop: any;

  utilityObj={
    anandiMaaDocAndHW:1200,
    anandiMaaInCountries:4,
    anandiMaaRollingCount:{
      mothersReached:130000,
      partnersJoined:16,
      statesCovered:15,
      villageCovered:1400,
      visitsMade:300000
    },
    fetomaxTotalMothers:'2L',
    fetomaxTotalStates:20,
    homeRollingCount:{
      totalCountries:5,
      totalMothers:500000,
      totalOrgs:750,
      totalStates:25
    },
    totalDoctors:2000
  }

  constructor(private landingService:LandingDataService) {
    this.landingService.currentPlatformNumbers.subscribe(data=>{
      if(data && data.anandiMaaRollingCount){
        this.totalVisitsCount=data.anandiMaaRollingCount.visitsMade;
        this.totalMothersCount=data.anandiMaaRollingCount.mothersReached;
        this.totalVillagesCount=data.anandiMaaRollingCount.villageCovered;
        this.totalStatesCount=data.anandiMaaRollingCount.statesCovered;
        this.totalPartnersCount=data.anandiMaaRollingCount.partnersJoined;
      } 
    });
   }
  ngAfterContentInit(): void {
    var target = document.getElementById('anandimaa-counter');
    console.log(target.offsetTop + 130, window.innerHeight)
    if(window.innerHeight >= target.offsetTop + 130){
      if(this.visitsCount != this.totalVisitsCount && this.visitsCount < (this.totalVisitsCount-299)){
        this.visitsCount = (this.totalVisitsCount-299);
      }else if(this.visitsCount == this.totalVisitsCount){
        this.visitsCount = this.totalVisitsCount;
      }

      this.visitsCountStop = setInterval(()=>{
        
        if(this.visitsCount < this.totalVisitsCount){
          this.visitsCount++;
        }
        if(this.visitsCount >= this.totalVisitsCount){
          clearInterval(this.visitsCountStop);
        }
      },50)

      if(this.mothersCount != this.totalMothersCount && this.mothersCount < (this.totalMothersCount-299)){
        this.mothersCount = (this.totalMothersCount-299);
      }else if(this.mothersCount == this.totalMothersCount){
        this.mothersCount = this.totalMothersCount;
      }

      this.mothersCountStop = setInterval(()=>{
        
        if(this.mothersCount < this.totalMothersCount){
          this.mothersCount++;
        }
        if(this.mothersCount >= this.totalMothersCount){
          clearInterval(this.mothersCountStop);
        }
      },50)

      if(this.villagesCount != this.totalVillagesCount && this.villagesCount < (this.totalVillagesCount-299)){
        this.villagesCount = (this.totalVillagesCount-299);
      }else if(this.villagesCount == this.totalVillagesCount){
        this.villagesCount = this.totalVillagesCount;
      }
      
      this.villagesCountStop = setInterval(()=>{ 
        if(this.villagesCount < this.totalVillagesCount){
          this.villagesCount++;
        }
        if(this.villagesCount >= this.totalVillagesCount){
          clearInterval(this.villagesCountStop);
        }
      },20)
      
      this.statesCountStop = setInterval(()=>{
        if(this.statesCount < this.totalStatesCount){
          this.statesCount++;
        }
        
        if(this.statesCount >= this.totalStatesCount){
          clearInterval(this.statesCountStop);
        }
      },200)

      this.partnersCountStop = setInterval(()=>{
        if(this.partnersCount < this.totalPartnersCount){
          this.partnersCount++;
        }
        if(this.partnersCount >= this.totalPartnersCount){
          clearInterval(this.partnersCountStop);
        }
      },200)
    }
  }

  ngOnInit() { 
    this.getUtility();
  }
  
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
        
      var target = document.getElementById('anandimaa-counter');
      const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    
      if(offset >= target.offsetHeight/2 && offset <= target.offsetTop) {

        if(this.visitsCount != this.totalVisitsCount && this.visitsCount < (this.totalVisitsCount-299)){
          this.visitsCount = (this.totalVisitsCount-299);
        }else if(this.visitsCount == this.totalVisitsCount){
          this.visitsCount = this.totalVisitsCount;
        }
  
        this.visitsCountStop = setInterval(()=>{
          
          if(this.visitsCount < this.totalVisitsCount){
            this.visitsCount++;
          }
          if(this.visitsCount >= this.totalVisitsCount){
            clearInterval(this.visitsCountStop);
          }
        },50)
  
        if(this.mothersCount != this.totalMothersCount && this.mothersCount < (this.totalMothersCount-299)){
          this.mothersCount = (this.totalMothersCount-299);
        }else if(this.mothersCount == this.totalMothersCount){
          this.mothersCount = this.totalMothersCount;
        }
  
        this.mothersCountStop = setInterval(()=>{
          
          if(this.mothersCount < this.totalMothersCount){
            this.mothersCount++;
          }
          if(this.mothersCount >= this.totalMothersCount){
            clearInterval(this.mothersCountStop);
          }
        },50)
        
        this.villagesCountStop = setInterval(()=>{
          
          if(this.villagesCount < this.totalVillagesCount){
            this.villagesCount++;
          }
          if(this.villagesCount >= this.totalVillagesCount){
            clearInterval(this.villagesCountStop);
          }
        },20)
        
        this.statesCountStop = setInterval(()=>{
          if(this.statesCount < this.totalStatesCount){
            this.statesCount++;
          }
          
          if(this.statesCount >= this.totalStatesCount){
            clearInterval(this.statesCountStop);
          }
        },200)
  
        this.partnersCountStop = setInterval(()=>{
          if(this.partnersCount < this.totalPartnersCount){
            this.partnersCount++;
          }
          if(this.partnersCount >= this.totalPartnersCount){
            clearInterval(this.partnersCountStop);
          }
        },200)
      }
    }
  }

  getUtility(){
    let openingsData = this.landingService.getUtility().subscribe(data => {
      if(data){
        let obj={
          anandiMaaDocAndHW:data['anandiMaaDocAndHW']?data['anandiMaaDocAndHW']:0,
          anandiMaaInCountries:data['anandiMaaDocAndHW']?data['anandiMaaInCountries']:0,
          anandiMaaRollingCount:{
            mothersReached:data['anandiMaaRollingCount']['mothersReached']?data['anandiMaaRollingCount']['mothersReached']:0,
            partnersJoined:data['anandiMaaRollingCount']['partnersJoined']?data['anandiMaaRollingCount']['partnersJoined']:0,
            statesCovered:data['anandiMaaRollingCount']['statesCovered']?data['anandiMaaRollingCount']['statesCovered']:0,
            villageCovered:data['anandiMaaRollingCount']['villageCovered']?data['anandiMaaRollingCount']['villageCovered']:0,
            visitsMade:data['anandiMaaRollingCount']['visitsMade']?data['anandiMaaRollingCount']['visitsMade']:0
          },
          fetomaxTotalMothers:data['fetomaxTotalMothers']?data['fetomaxTotalMothers']:0,
          fetomaxTotalStates:data['fetomaxTotalStates']?data['fetomaxTotalStates']:0,
          homeRollingCount:{
            totalCountries:data['homeRollingCount']['totalCountries']?data['homeRollingCount']['totalCountries']:0,
            totalMothers:data['homeRollingCount']['totalMothers']?data['homeRollingCount']['totalMothers']:0,
            totalOrgs:data['homeRollingCount']['totalOrgs']?data['homeRollingCount']['totalOrgs']:0,
            totalStates:data['homeRollingCount']['totalStates']?data['homeRollingCount']['totalStates']:0
          },
          totalDoctors:data['totalDoctors']?data['totalDoctors']:0
        }

        obj=JSON.parse(JSON.stringify(obj));
        //Set Data
        this.landingService.setPlatformNumbers(obj);
      }else{
        this.landingService.setPlatformNumbers(this.utilityObj);
      }
      openingsData.unsubscribe();
    })
  }

}
