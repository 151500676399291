import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class OpeningsService {

  constructor(private afs: AngularFirestore) { }

  getOpenings(){
    return this.afs.collection('Openings', ref => ref.where('isActive','==',true).orderBy('documentId','asc')).valueChanges();
  }

  getOpeningsDetails(id){
    return this.afs.collection('Openings', ref => ref.where('documentId','==',id)).valueChanges();
  }
}
