import { Component, OnInit, HostListener, AfterContentInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit, AfterContentInit {

  constructor(private router: Router,
    private title: Title,
    private meta: Meta) {
      
    this.title.setTitle('Careers | Jobs Opening in CareMother');
    this.meta.updateTag({ name: 'description', content: "Share your updated resume at hr@dotohealth.com" })
    this.meta.updateTag({ name: 'keywords', content: "Careers, Jobs Opening in CareMother"})
    }

  ngOnInit() {
    window.scrollTo(0, 0);}
  
  isFixed: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (offset > 400) {
        this.isFixed = true;
      } else if(offset <= 500){
        this.isFixed = false
      }
      else {
        this.isFixed = false;
      }
    }
  }


  ngAfterContentInit(): void {
    
    if(this.router.url.includes('#openings')){
      
      let element = document.getElementById('openings');
      element.scrollIntoView({behavior:'smooth'});
    }
  }

}
