import { Component, OnInit } from '@angular/core';
import { LandingDataService } from 'src/app/services/landing-data.service';

@Component({
  selector: 'app-stay-tuned',
  templateUrl: './stay-tuned.component.html',
  styleUrls: ['./stay-tuned.component.scss']
})
export class StayTunedComponent implements OnInit {

  stayTunedList:any[]=[
    {
      title:`CareMother FetoMax Won the “Best Stall Award”.`,
      description:"CareMother FetoMax (A Wireless-Portable NST/CTG Device) exhibited at Nagpur Obstetrics And Gynecological Society - GYNECON 2021 won the best stall award with a huge turnout of doctors.",
      media:{
        name:"image 1.jpeg",
        downloadUrl:'../../../../assets/images/home/updates/image 1.jpeg'
      },
      isActive:true,
      createdBy:'',
      documentId:'',
      modifiedOn:''
    },
    {
      title:"CareMother AnandiMaa has reached 12+ States in India.",
      description:"CareMother Anandimaa - an AI enabled, high risk pregnancy management platform has now reached 12+ states with the support of 1200+ health workers and doctors who are caring for more than 65,000+ expecting mothers.",
      media:{
        name:"image 2.jpg",
        downloadUrl:'../../../../assets/images/home/updates/image 2.jpg'
      },
      isActive:true,
      createdBy:'',
      documentId:'',
      modifiedOn:''
    },
    {
      title:"CareMother in conversation with Oxford Policy Management.",
      description:"Oxford Policy Management invited Shantanu Pathak (CEO) to share his views and efforts on the last mile and consultation on designing Home Based Pregnancy Care with several other great folks.",
      media:{
        name:"image 3.jpeg",
        downloadUrl:'../../../../assets/images/home/updates/image 3.jpg'
      },
      isActive:true,
      createdBy:'',
      documentId:'',
      modifiedOn:''
    }
  ];

  constructor(private landingService:LandingDataService) { }

  ngOnInit() {
    this.getStayTuned();
  }


  getStayTuned(){
    const stuTunedObs=this.landingService.getStayTuned().subscribe((res)=>{
      if(res.length>0){
        this.stayTunedList=res;
        // console.log('Stay Tuned',res);
      }
      stuTunedObs.unsubscribe();
    });
  }
}
