<section>
    <div class="container inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block" >
                    <h1 class="mb-3 text-center text-white">Work With Us</h1>
                    <p class="mb-4 text-center text-white font-16">
                        We make sure to encourage you during your personal and professional development.<br>
                        Kindly mail your resume to <a href="" class="color-secondary mail">hr@dotohealth.com</a>
                    </p>
                    <a href="mailto:sales@caremother.in" color="secondary" button-filled>Email Us</a>
                </div>
            </div>   
        </div>
    </div>
</section>