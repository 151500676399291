<section id="publications-section" class="bg-color-primary-light">
    <div class="container-fluid inner-section">
        <div class="row mb-5" >
            <div class="col-md-12 text-center">    
                <h1 class="text-white">Discover Publications</h1>
            </div>
        </div>
        <div class="row" >
            <div class="col-12">
                <div class="glide-container">
                    <div class="glide glide__publications">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <a href="https://www.mhtf.org/2019/09/13/personalized-maternal-care-intervention-in-a-tribal-community-in-india/" target="_blank">
                                        <div class="card" >
                                            <div class="card-body">
                                                <div class="display-block">
                                                    <div class="d-flex align-items-center">
                                                        <h4 class="color-secondary">Maternal Health Task Force</h4>
                                                    </div> 
                                                    <p class="color-primary font-15 mt-3">
                                                        Personalised maternal care intervention in a tribal community in
                                                        India, Maternal Health Task Force (MHTF) blog, Harvard School of Public
                                                        Health, 2019
                                                    </p>
                                                </div>
                                                <div>
                                                    <hr class="bg-color-light my-3">
                                                    <p class="color-secondary font-15" target="_blank"><span class="fa fa-file-text-o mr-2 color-secondary" aria-hidden="true"></span>Read this <i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                                </div>
                                            </div>
                                        </div> 
                                    </a>
                                </li>
                                <li class="glide__slide">
                                    <a href="./assets/publictions/GHIC.pdf#toolbar=0&navpanes=0&scrollbar=0" target="_blank">
                                        <div class="card" >
                                            <div class="card-body">
                                                <div class="display-block">
                                                    <div class="d-flex align-items-center">
                                                        <h4 class="color-secondary">
                                                            Global Health and 
                                                            Innovation Conference(GHIC)</h4>
                                                    </div>
                                                    <p class="color-primary font-15 mt-3">
                                                        U-Act: Wearable patch to monitor uterine activity to predict
                                                        preterm labour, Global Health and Innovation Conference (GHIC), Yale
                                                        University, USA 2019 
                                                    </p>
                                                </div>
                                                <div>
                                                    <hr class="bg-color-light my-3">
                                                    <p class="color-secondary font-15" target="_blank"><span class="fa fa-file-text-o mr-2 color-secondary" aria-hidden="true"></span>Read this <i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                                </div>
                                            </div>
                                        </div> 
                                    </a>
                                </li>
                                <li class="glide__slide">
                                    <a href="https://www.oecd-ilibrary.org/sites/9789264309333-6-en/index.html?itemId=/content/component/9789264309333-6-en" target="_blank">
                                        <div class="card" >
                                            <div class="card-body">
                                                <div class="display-block">
                                                    <div class="d-flex align-items-center">
                                                        <h4 class="color-secondary">
                                                            The Organisation for Economic
                                                            Co-operation and Development</h4>
                                                    </div>
                                                    <p class="color-primary font-15 mt-3">
                                                        Bringing affordable and accessible maternal 
                                                        healthcare to the poorest districts 
                                                        of India, OECD, 2018
                                                    </p>
                                                </div>
                                                <div>
                                                    <hr class="bg-color-light my-3">
                                                    <p class="color-secondary font-15" target="_blank"><span class="fa fa-file-text-o mr-2 color-secondary" aria-hidden="true"></span>Read this <i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                                </div>
                                            </div>
                                        </div> 
                                    </a>
                                </li>
                                <li class="glide__slide">
                                    <a href="./assets/publictions/World Bank Ideas For Action 2019.pdf#toolbar=0&navpanes=0&scrollbar=0" target="_blank">
                                        <div class="card" >
                                            <div class="card-body">
                                                <div class="display-block">
                                                    <div class="d-flex align-items-center">
                                                        <h4 class="color-secondary">
                                                            The World Bank Group - Ideas For Action(I4A)
                                                        </h4>
                                                    </div>
                                                    <p class="color-primary font-15 mt-3">
                                                        Ideas For Action (I4A), IMF, 
                                                        The World Bank Group and Wharton
                                                        School, page (1-18), 2019
                                                    </p>
                                                </div>
                                                <div>
                                                    <hr class="bg-color-light my-3">
                                                    <p class="color-secondary font-15" target="_blank"><span class="fa fa-file-text-o mr-2 color-secondary" aria-hidden="true"></span>Read this <i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                                </div>
                                            </div>
                                        </div> 
                                    </a>
                                </li>
                                <li class="glide__slide">
                                    <a href="./assets/publictions/GSI.pdf#toolbar=0&navpanes=0&scrollbar=0" target="_blank">
                                        <div class="card" >
                                            <div class="card-body">
                                                <div>
                                                    <div class="d-flex align-items-center">
                                                        <h4 class="color-secondary">
                                                            G20 Global Solutions Journal
                                                        </h4>
                                                    </div>
                                                    <p class="color-primary font-15 mt-3">
                                                        Policy brief for G20 Global Solutions Journal 2020 (under review)
                                                    </p>
                                                </div>
                                                <div>
                                                    <hr class="bg-color-light my-3">
                                                    <p class="color-secondary font-15" target="_blank"><span class="fa fa-file-text-o mr-2 color-secondary" aria-hidden="true"></span>Read this <i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                                </div>
                                            </div>
                                        </div> 
                                    </a>
                                </li>
                                <li class="glide__slide">
                                    <a href="./assets/publictions/PCA.pdf#toolbar=0&navpanes=0&scrollbar=0" target="_blank">
                                        <div class="card" >
                                            <div class="card-body">
                                                <div>
                                                    <div class="d-flex align-items-center">
                                                        <h4 class="color-secondary">
                                                            Fetal ECG White Paper
                                                        </h4>
                                                    </div>
                                                    
                                                    <p class="color-primary font-15 mt-3">
                                                        Principal component analysis based single-channel, 
                                                        non-invasive fetal ECG extraction -(white paper)
                                                    </p>
                                                </div>
                                                <div>
                                                    <hr class="bg-color-light my-3">
                                                    <p class="color-secondary font-15" target="_blank"><span class="fa fa-file-text-o mr-2 color-secondary" aria-hidden="true"></span>Read this <i class="fa fa-long-arrow-right" aria-hidden="true"></i></p>
                                                </div>
                                            </div>
                                        </div> 
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="glide__bullets" data-glide-el="controls[nav]">
                            <button class="glide__bullet" data-glide-dir="=0"></button>
                            <button class="glide__bullet" data-glide-dir="=1"></button>
                            <button class="glide__bullet" data-glide-dir="=2"></button>
                            <button class="glide__bullet" data-glide-dir="=3"></button>
                            <button class="glide__bullet" data-glide-dir="=4"></button>
                            <button class="glide__bullet" data-glide-dir="=5"></button>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>