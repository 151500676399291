<section id="babybeat-section">
    <div class="section-bg bg-color-secondary-lighter"></div>
    <div class="container inner-section">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-6 inner-details mb-5 mb-lg-0" >
                <div class="details-container">
                    <p class="color-light">CareMother</p>
                    <h1 class="mb-3 color-secondary">BabyBeat</h1>
                    <p class="mb-3 color-primary my-4">
                        A medical grade DIY and wireless fetal <br>
                        heart monitor that enables a pregnant woman <br>
                        to perform Non-Stress-Test (NST) at home, <br>
                        hear their baby's heart beat, and share real-time <br>
                        reports with their doctor in three easy steps.
                    </p>
                    <div class="intro-buttons">
                        <a routerLink="/offering/babybeat" color="secondary" button-filled>
                            Know More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 inner-visual" >
                <div>
                    <div class="inner-img">
                        <!--<img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/products/babybeat.jpg">-->
                        <video src="../../../../assets/video/babybeat.mp4" [muted]="'muted'" playsinline preload="auto" loop autoplay></video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>