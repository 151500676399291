<section id="story-section">
    <div class="container-fluid inner-section">
      <div class="row" style="margin-bottom: 40px; padding-left: 30px;" >
          <div class="col-12">
            <h1 class="color-secondary">Our Story</h1>
          </div>
      </div>
      <div class="timeline" >
        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" class="world-map" src="../../../../../assets/images/company/about/journey/world.svg">
        <div class="timeline-content"  #elemt (mousedown)="startDragging($event, false, elemt)" (mouseup)="stopDragging($event, false)"
        (mouseleave)="stopDragging($event, false)" (mousemove)="moveEvent($event, elemt)">
            <div class="row content-inner">
                <div class="col content-block part-one px-0">
                    <div class="h-100 text-center d-flex flex-column align-items-center justify-content-end">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/about/journey/story icons-01.png">
                        <h2 class="mt-2 font-25 color-primary">2015</h2>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <p class="font-14 color-light">
                            &bull; Incubation at SINE, IIT Bombay <br>
                            &bull; Launch of AnandiMaa<br class="break">
                            digital platform for last mile for<br class="break">
                            public healthcare providers <br>
                            &bull; The Innovation was recognised<br class="break">
                            by UN Women
                        </p>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="h-100 text-center d-flex flex-column align-items-center justify-content-end">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/about/journey/story icons-03.png">
                        <h2 class="mt-2 font-25 color-primary">2018-2019</h2>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <p class="font-14 color-light">
                            &bull; FetoMax commercially launched <br>
                            &bull; 300 FetoMax units were sold out <br>
                            &bull; Launched pilots of AnandiMaa and<br class="break">
                            FetoMax in Nigeria, Bangladesh<br class="break">
                            and Afghanistan 
                        </p>
                    </div>
                </div>
                <div class="col content-block part-last px-0">
                    <div class="h-100 text-center d-flex flex-column align-items-center justify-content-end">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/about/journey/story icons-05.png">
                        <h2 class="mt-2 font-25 color-primary">2022</h2>
                    </div>
                </div>
            </div>
            <div class="row content-inner">
                <div class="col content-block part-one px-0">
                    <div class="text-center">
                        <div class="divider">
                            <div class="center-circle bg-color-secondary"></div>
                            <hr class="bg-color-secondary">
                        </div>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <div class="divider">
                            <div class="center-circle bg-color-secondary"></div>
                            <hr class="bg-color-secondary">
                        </div>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <div class="divider">
                            <div class="center-circle bg-color-secondary"></div>
                            <hr class="bg-color-secondary">
                        </div>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <div class="divider">
                            <div class="center-circle bg-color-secondary"></div>
                            <hr class="bg-color-secondary">
                        </div>
                    </div>
                </div>
                <div class="col content-block part-last px-0">
                    <div class="text-center">
                        <div class="divider">
                            <div class="center-circle bg-color-secondary"></div>
                            <hr class="bg-color-secondary">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row content-inner">
                <div class="col content-block part-one px-0">
                    <div class="text-center">
                        <p class="font-14 color-light">
                            Launched the CareMother mission<br class="break">
                            to innovate for quality pregnancy care<br class="break"> 
                            with the President of India's Innovation<br class="break">
                            Award to the innovation
                        </p>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/about/journey/story icons-02.png">
                        <h2 class="mt-2 font-25 color-primary">2016-2017</h2>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <p class="font-14 color-light">
                            &bull; Funding received from two <br class="break">
                            international impact funds to<br class="break">
                            establish our business model <br>
                            &bull; Launched India's first digital <br class="break">
                             and wireless fetal monitoring <br class="break">
                             solution: FetoMax <br>
                            &bull; Global Awards for AnandiMaa <br class="break">
                            and FetoMax 
                        </p>
                    </div>
                </div>
                <div class="col content-block px-0">
                    <div class="text-center">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../../assets/images/company/about/journey/story icons-04.png">
                        <h2 class="mt-2 font-25 color-primary">2020-2021</h2>
                    </div>
                </div>
                <div class="col content-block part-last px-0">
                    <div class="text-center">
                        <p class="font-14 color-light">
                            &bull; 3 Lakh+ tests performed on<br class="break">
                            FetoMax and 2 Lakh+ mothers were<br class="break">
                            served through AnandiMaa <br>
                            &bull; Launched a Self-Monitoring Solution<br class="break">
                            named: BabyBeat 
                        </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</section>