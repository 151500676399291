<section class="">
    <div class="container-fluid inner-section">
        <div class="row align-items-center justify-content-center justify-content-lg-unset">
            <div class="col-12 col-sm-8 col-md-7 col-lg-5">
                <div class="details-container mb-5 mb-lg-0" >
                    <!-- <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" class="mb-4" src="../../../../../assets/images/products/fetosense/intro/fetosense logo_green.png"> -->
                    <h1 class="color-secondary font-45 mb-2">FetoMax</h1>
                    <h2 class="color-primary mb-4">
                        A smart-phone based interactive and advanced <br>
                        NST/CTG machine
                    </h2>
                    <div>
                        <a href="https://forms.gle/QaAGwLQShknG96FV9" color="secondary" button-filled>Get Free Demo</a>            
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8 col-md-7 col-lg-7">
                <div class="image-container mt-5 mt-lg-0 d-flex align-items-center justify-content-end">
                    <img alt="We’re CareMother, A Digital Maternity Solution Platform For Healthcare Providers" src="../../../../../assets/images/products/fetosense/intro/feto-product.png">
                </div>
            </div>
        </div>
    </div>
</section>