<section id="featured-section">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="color-primary">We Got Featured In</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12" >
                <div class="glide-container">
                    <div class="glider__featured__in glide">
                        <div class="glide__track" data-glide-el="track">
                            <ul class="glide__slides">
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-20.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-21.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-22.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-23.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-24.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-30.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-31.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-25.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-26.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-27.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-28.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-29.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-32.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-33.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-34.svg"> 
                                </li>
                                <li class="glide__slide">
                                    <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother" src="../../../../assets/images/home/featured/featured in logos-35.svg"> 
                                </li>
                            </ul>
                        </div>
                        <!--<img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/featured/left faded.png" class="left-faded">
                        <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/featured/right faded.png" class="right-faded">-->
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>