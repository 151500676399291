<section class="">
    <!-- <img class="marketer-logo top-right d-none d-lg-block" src="../../../../../assets/images/products/babybeat/intro/bsv logo.png" alt="marketed by bsv"> -->
    <div class="container-fluid inner-section">
        <div class="row align-items-center justify-content-center justify-content-lg-unset">
            <div class="col-12 col-sm-8 col-md-7 col-lg-5">
                <div class="details-container mb-5 mb-lg-0">
                    <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" class="mb-4" src="../../../../../assets/images/products/babybeat/intro/babybeatlogo-white.png">
                    <h1 class="text-white font-45 mb-3">BabyBeat</h1>
                    <h2 class="text-white mb-4">
                        Listen to the rhythm of your baby’s heartbeat <br>
                        now at home
                    </h2>
                    <div>
                        <a href="https://forms.gle/QaAGwLQShknG96FV9" color="white" button-filled>Know More</a>            
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-8 col-md-7 col-lg-7 d-none d-lg-block">
                <div class="image-container mt-5 mt-lg-0 d-flex align-items-center justify-content-end">
                    <!-- <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/intro/bb banner product1.png"> -->
                     <!-- <div class="glide-container"> -->
                        <div class="glide glide__slider">
                            <div class="glide__track" data-glide-el="track">
                                <ul class="glide__slides">
                                    <!--Slider 1-->
                                    <li class="glide__slide">
                                        <div class="row slide-inner align-items-center">
                                            <div class="col-12 img-container">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"
                                                    src="../../../../assets/images/products/babybeat/intro/bb banner product1.png">
                                                <div class="title-container">
                                                    <p class="doppler-title">BabyBeat- Handheld</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--Slide 2-->
                                    <li class="glide__slide">
                                        <div class="row slide-inner align-items-center">
                                            <div class="col-11 img-container">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"
                                                    src="../../../../assets/images/products/babybeat/intro/bb banner product2.png">
                                                <div class="title-container">
                                                    <p class="doppler-title">BabyBeat- Belts</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--Slide 3-->
                                    <li class="glide__slide">
                                        <div class="row slide-inner align-items-center">
                                            <div class="col-12 img-container">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"
                                                    src="../../../../assets/images/products/babybeat/intro/bb banner product3.png">
                                                <div class="title-container">
                                                    <p class="doppler-title">BabyBeat- Pro</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
    
                                </ul>
                            </div>
                            <div class="glide__bullets" data-glide-el="controls[nav]">
                                <button class="glide__bullet" data-glide-dir="=0"></button>
                                <button class="glide__bullet" data-glide-dir="=1"></button>
                                <button class="glide__bullet" data-glide-dir="=2"></button>
                            </div>
                        </div>
                     <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<div class="small-product-image">
    <div class="container-fluid inner-section">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-md-7 d-block d-lg-none">
                <!-- <div class="mt-5">
                    <img class="marketer-logo" alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/intro/bsv logo.png">
                </div> -->
                <div class="mb-5">
                    <!-- <img alt="BabyBeat Fetal Monitor in India, CareMother, CareMother providing NST/CTG tests at home" src="../../../../../assets/images/products/babybeat/intro/bb banner product1.png"> -->
                    <div class="image-container">
                        <div class="glide glide__slider">
                            <div class="glide__track" data-glide-el="track">
                                <ul class="glide__slides">
                                    <!--Slider 1-->
                                    <li class="glide__slide">
                                        <div class="row slide-inner align-items-center">
                                            <div class="col-12 img-container">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"
                                                    src="../../../../assets/images/products/babybeat/intro/bb banner product1.png">
                                                <div class="title-container">
                                                    <p class="doppler-title">BabyBeat- Handheld</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--Slide 2-->
                                    <li class="glide__slide">
                                        <div class="row slide-inner align-items-center">
                                            <div class="col-12 img-container">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"
                                                    src="../../../../assets/images/products/babybeat/intro/bb banner product2.png">
                                                <div class="title-container">
                                                    <p class="doppler-title">BabyBeat- Belts</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <!--Slide 3-->
                                    <li class="glide__slide">
                                        <div class="row slide-inner align-items-center">
                                            <div class="col-12 img-container">
                                                <img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, FetoMax, CareMother"
                                                    src="../../../../assets/images/products/babybeat/intro/bb banner product3.png">
                                                <div class="title-container">
                                                    <p class="doppler-title">BabyBeat- Pro</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
    
                                </ul>
                            </div>
                            <div class="glide__bullets" data-glide-el="controls[nav]">
                                <button class="glide__bullet" data-glide-dir="=0"></button>
                                <button class="glide__bullet" data-glide-dir="=1"></button>
                                <button class="glide__bullet" data-glide-dir="=2"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="banner-bottom">
    <div class="container-fluid inner-section">
        <div class="row">
            <div class="col-12">
                <div class="inner-block d-flex align-items-center justify-content-center">
                    <!-- <img class="d-none d-lg-block" src="../../../../../assets/images/products/babybeat/intro/bsv logo small.png" alt="bsv logo"> -->
                    <p class="color-primary">Product 'BabyBeat' is of DOTO Health Private Limited.</p>
                </div>
            </div>
        </div>
    </div>
</div>