<section id="anandimaa-section">
    <div class="section-bg bg-color-secondary-lighter"></div>
    <div class="container inner-section">
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-6 inner-visual" >
                <div>
                    <div class="inner-img">
                        <!--<img alt="NST / CTG Machine in India, Fetal Monitor, smartphone based portable NST/CTG machine, portable NST/CTG machine, Fetosense, CareMother" src="../../../../assets/images/home/products/anandimaa.jpg">-->
                        <video src="../../../../assets/video/anandimaa.mp4" [muted]="'muted'" preload="auto" playsinline loop autoplay></video>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 inner-details mb-5 mb-lg-0" >
                <div class="details-container">
                    <p class="color-light">CareMother</p>
                    <h1 class="mb-3 color-secondary">careHER</h1>
                    <p class="mb-3 color-primary my-4">
                        Health Entrepreneurs enabling healthcare providers <br>
                        to offer quality care to women at last mile .<br>
                    </p>
                    <div class="intro-buttons">
                        <a routerLink="/offering/careHEr" color="secondary" button-filled>
                            Know More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>