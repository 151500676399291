<section id="publications-section">
    <!--<div class="top-bg bg-color-secondary-light"></div>-->
    <div class="container inner-section">
        <div class="row" >
            <div class="col-12 text-center" style="margin-bottom: 80px;">
                <h1 class="color-secondary">Don’t miss out our latest blogs and articles</h1>
            </div>
        </div>
        <div class="row mb-5 justify-content-center">
            <div class="col-md-6 col-lg-4 col-sm-6 mb-3">
                <div class="card o-hidden" >
                    <img alt="Dignified life through apt healthcare for women and families - CareMother" class="card-img-top" src="../../../../assets/images/home/blogs/blog-img1.jpg">
                    <div class="card-body">
                        <div>
                            <span class="bg-color-secondary text-white blog-category">Pregnancy Care</span>
                            <h4 class="color-primary mt-4">Dignified life through apt healthcare for women and families</h4>
                        </div>
                        <div>
                            <hr class="bg-color-light my-3">
                            <div>
                                <i class="fa fa-user mr-2 color-secondary" aria-hidden="true"></i><span class="color-secondary">Published by Aditya Kulkarni</span>
                            </div>
                            <div>
                                <i class="fa fa-clock-o mr-2 color-secondary" aria-hidden="true"></i><span class="color-primary">9 aug 2020 | 3 mins read</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-3">
                <div class="card o-hidden" >
                    <img alt="Pregnancy Journeys During the Pandemic - CareMother" class="card-img-top" src="../../../../assets/images/home/blogs/blog-img2.jpg">
                    <div class="card-body">
                        <div>
                            <span class="bg-color-secondary text-white blog-category">Pregnancy Care</span>
                            <h4 class="color-primary mt-4">Pregnancy Journeys During the Pandemic</h4>
                        </div>
                        <div>
                            <hr class="bg-color-light my-3">
                            <div>
                                <i class="fa fa-user mr-2 color-secondary" aria-hidden="true"></i><span class="color-secondary">Published by Tarana Mendiratta</span>
                            </div>
                            <div>
                                <i class="fa fa-clock-o mr-2 color-secondary" aria-hidden="true"></i><span class="color-primary">22 Jul 2020 | 3 mins read</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-6 mb-3">
                <div class="card o-hidden" >
                    <img alt="COVID 19 – Expediting the Evolution of Home Healthcare Market Segment - CareMother" class="card-img-top" src="../../../../assets/images/home/blogs/blog-img3.jpg">
                    <div class="card-body">
                        <div>
                            <span class="bg-color-secondary text-white blog-category">Pregnancy Care</span>
                            <h4 class="color-primary mt-4">COVID 19 – Expediting the Evolution of Home Healthcare Market Segment</h4>
                        </div>
                        <div>
                            <hr class="bg-color-light my-3">
                            <div>
                                <i class="fa fa-user mr-2 color-secondary" aria-hidden="true"></i><span class="color-secondary">Published by Soumitra Sharma</span>
                            </div>
                            <div>
                                <i class="fa fa-clock-o mr-2 color-secondary" aria-hidden="true"></i><span class="color-primary">12 sept 2020 | 2 mins read</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row justify-content-center">
            <a class="btn-filled-secondary" target="_blank" href="https://blogs.caremother.in/" >View More Blogs</a>            
        </div>-->
    </div>
</section>